import { CompaniesComplaintEnum } from "../../enums/companies-complaint.enum";
import { FinancialInstitutionEnum } from "../../enums/financial-institution.enum";
import { FinishFormReasoningEnum } from "../../enums/finish-form-reasoning.enum";
import { PaymentsGatewayEnum } from "../../enums/payments-gateway.enum";
import { TakedownSlaEnum } from "../../enums/takedown-sla.enum";
import { ThreatDaysEnum } from "../../enums/threat-days.enum";
import { ThreatPlatformEnum } from "../../enums/threat-platform.enum";
import { ThreatTypeEnum } from "../../enums/threat-type.enum";
import { ITakedownComplaintForm } from "../../interfaces/takedown-complaint-form.interface";
import { ITakedownsFilter } from "../../interfaces/takedown-filter.interface";
import { ITakedownPixForm } from "../../interfaces/takedown-pix-form.interface";
import { ITakedowns } from "../../interfaces/takedown.interface";
import { ITakedownsTable } from "../../interfaces/takedowns-table.interface";
import { IThreatAdsForm } from "../../interfaces/threat-ads-form.interface";
import { IThreatContentForm } from "../../interfaces/threat-content-form.interface";
import { IThreatProfileForm } from "../../interfaces/threat-profile-form.interface";
import { IThreatSiteForm } from "../../interfaces/threat-site-form.interface";
import { IThreatsRegister } from "../../interfaces/threats-register.interface";
import { IThreatsTable } from "../../interfaces/threats-table.interface";
import { IThreats } from "../../interfaces/threats.interface";
import { ActionTypesEnum } from "./types.enum";

export const submitForm = (formData: any) => {
  return {
    type: ActionTypesEnum.SUBMIT_FORM,
    payload: formData,
  };
};

export const submitFormSuccess = () => {
  return {
    type: ActionTypesEnum.SUBMIT_FORM_SUCCESS,
  };
};

export const submitFormError = (error: any) => {
  return {
    type: ActionTypesEnum.SUBMIT_FORM_ERROR,
    payload: error,
  };
};

export const changeFilterText = (text: string) => ({
  type: ActionTypesEnum.CHANGE_FILTER_TEXT,
  payload: text,
});

export const changeFilterType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_FILTER_TYPE,
  payload: threatType,
});

export const changeFilterPlatform = (platform: ThreatPlatformEnum) => ({
  type: ActionTypesEnum.CHANGE_FILTER_PLATFORM,
  payload: platform,
});

export const changeFilterStatus = (status: boolean) => ({
  type: ActionTypesEnum.CHANGE_STATUS_PLATFORM,
  payload: status,
});

export const changeFilterIPF = (ipf: number | string) => ({
  type: ActionTypesEnum.CHANGE_FILTER_IPF,
  payload: ipf,
});

export const changeFilterDateRangeStart = (startDate: Date) => ({
  type: ActionTypesEnum.CHANGE_FILTER_DATE_RANGE_START,
  payload: startDate,
});

export const changeFilterDateRangeEnd = (endDate: Date) => ({
  type: ActionTypesEnum.CHANGE_FILTER_DATE_RANGE_END,
  payload: endDate,
});

export const changeTakedownFilterDateRangeStart = (startDate: Date) => ({
  type: ActionTypesEnum.CHANGE_TAKEDOWN_FILTER_DATE_RANGE_START,
  payload: startDate,
});

export const changeTakedownFilterDateRangeEnd = (endDate: Date) => ({
  type: ActionTypesEnum.CHANGE_TAKEDOWN_FILTER_DATE_RANGE_END,
  payload: endDate,
});

export const changeFilterDays = (days: ThreatDaysEnum) => ({
  type: ActionTypesEnum.CHANGE_FILTER_DAYS,
  payload: days,
});

export const changeTakedownsFilterDays = (days: ThreatDaysEnum) => ({
  type: ActionTypesEnum.CHANGE_TAKEDOWNS_FILTER_DAYS,
  payload: days,
});

export const changeThreatsList = (list: IThreats[]) => ({
  type: ActionTypesEnum.CHANGE_LIST,
  payload: list
})

export const changeThreatsTable = (table: IThreatsTable[]) => ({
  type: ActionTypesEnum.CHANGE_TABLE,
  payload: table
})

export const changeThreatsCurrentIds = (ids: string[]) => ({
  type: ActionTypesEnum.CHANGE_CURRENT_IDS,
  payload: ids
})

export const changeThreatsCurrent = (threat: IThreats) => ({
  type: ActionTypesEnum.CHANGE_CURRENT,
  payload: threat
})

export const changeSiteFormEvidenceLink = (domain: string) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_EVIDENCE_LINK,
  payload: domain,
});

export const changeSiteFormDomain = (domain: string) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_DOMAIN,
  payload: domain,
});

export const changeSiteFormEvidenceFiles = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_EVIDENCE_FILES,
  payload: files,
});

export const changeSiteFormType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_TYPE,
  payload: threatType,
});

export const changeSiteFormUrl = (url: string) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_URL,
  payload: url,
});

export const changeSiteFormSearchDate = (searchDate: Date) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_SEARCH_DATE,
  payload: searchDate,
});

export const changeSiteFormIsActive = (isActive: boolean) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM_IS_ACTIVE,
  payload: isActive,
});
// Profile Form Actions
export const changeProfileFormType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_TYPE,
  payload: threatType,
});

export const changeProfileFormUrl = (url: string) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_URL,
  payload: url,
});

export const changeProfileFormSearchDate = (searchDate: Date) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_SEARCH_DATE,
  payload: searchDate,
});

export const changeProfileFormEvidenceLink = (link: string) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_EVIDENCE_LINK,
  payload: link,
});

export const changeProfileFormEvidenceFiles = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_EVIDENCE_FILES,
  payload: files,
});

export const changeProfileFormProfile = (profile: string) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_PROFILE,
  payload: profile,
});

export const changeProfileFormPlatform = (platform: ThreatPlatformEnum) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_PLATFORM,
  payload: platform,
});

export const changeProfileFormIsActive = (isActive: boolean) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM_IS_ACTIVE,
  payload: isActive,
});
// Content Form Actions
export const changeContentFormEvidenceLink = (link: string) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_EVIDENCE_LINK,
  payload: link,
});

export const changeContentFormEvidenceFiles = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_EVIDENCE_FILES,
  payload: files,
});

export const changeContentFormType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_TYPE,
  payload: threatType,
});

export const changeContentFormUrl = (url: string) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_URL,
  payload: url,
});

export const changeContentFormProfile = (profile: string) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_CONTENT,
  payload: profile,
});

export const changeContentFormPlatform = (platform: ThreatPlatformEnum) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_PLATFORM,
  payload: platform,
});

export const changeContentFormIsActive = (isActive: boolean) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM_IS_ACTIVE,
  payload: isActive,
});
// Ads Form Actions
export const changeAdsFormEvidenceLink = (link: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_EVIDENCE_LINK,
  payload: link,
});

export const changeAdsFormEvidenceFiles = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_EVIDENCE_FILES,
  payload: files,
});

export const changeAdsFormType = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_TYPE,
  payload: threatType,
});

export const changeAdsFormStartDate = (startDate: Date) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_START_DATE,
  payload: startDate,
});

export const changeAdsFormSearchDate = (searchDate: Date) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_SEARCH_DATE,
  payload: searchDate,
});

export const changeAdsFormId = (id: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_ID,
  payload: id,
});

export const changeAdsFormPlatform = (platform: ThreatPlatformEnum) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_PLATFORM,
  payload: platform,
});

export const changeAdsFormUrl = (url: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_URL,
  payload: url,
});

export const changeAdsFormInstagramProfile = (instagramProfile: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_INSTAGRAM_PROFILE,
  payload: instagramProfile,
});

export const changeAdsFormAdvertiserName = (advertiserName: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_ADVERTISER_NAME,
  payload: advertiserName,
});

export const changeAdsFormAdvertiserId = (advertiserId: string) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_ADVERTISER_ID,
  payload: advertiserId,
});

export const changeAdsFormIsActive = (isActive: boolean) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM_IS_ACTIVE,
  payload: isActive,
});

export const getFraudsByCompanyId = (getFraudsDto: any) => ({
  type: ActionTypesEnum.GET_FRAUDS_BY_COMPANY,
  payload: getFraudsDto,
});

export const registerFraud = (fraudRegister: any) => ({
  type: ActionTypesEnum.REGISTER_FRAUD,
  payload: fraudRegister,
});

export const registerFraudFailure = (errorMessage: string) => ({
  type: ActionTypesEnum.REGISTER_FRAUD_FAILURE,
  payload: errorMessage,
});

export const addFraud = (newFraud: IThreats) => ({
  type: ActionTypesEnum.ADD_FRAUD,
  payload: newFraud,
});

export const updateFraud = (updatedFraud: IThreats) => ({
  type: ActionTypesEnum.UPDATE_FRAUD,
  payload: updatedFraud,
});

export const updateMultipleFrauds = (updatedFrauds: IThreats[]) => ({
  type: ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS,
  payload: updatedFrauds,
});

export const restoreFraud = (fraud: IThreats) => ({
  type: ActionTypesEnum.RESTORE_FRAUD,
  payload: fraud,
});

export const restoreMultipleFrauds = (frauds: IThreats[]) => ({
  type: ActionTypesEnum.RESTORE_MULTIPLE_FRAUDS,
  payload: frauds,
});

export const updateFraudFromSaga = (updatedFraud: IThreats) => ({
  type: ActionTypesEnum.UPDATE_FRAUD_FROM_SAGA,
  payload: updatedFraud,
});

export const updateMultipleFraudsFromSaga = (updatedFrauds: IThreats[]) => ({
  type: ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS_FROM_SAGA,
  payload: updatedFrauds,
});

export const cleanForms = () => ({
  type: ActionTypesEnum.CLEAN_FORMS,
});

export const setLoading = (isLoading: boolean) => ({
  type: ActionTypesEnum.IS_LOADING,
  payload: isLoading,
});

export const changeFraudsQuantityByState = (fraudsQuantityByState: any) => ({
  type: ActionTypesEnum.CHANGE_FRAUDS_QUANTITY_BY_STATE,
  payload: fraudsQuantityByState,
})

export const changeFraudsFilteredQuantityByState = (fraudsQuantityByState: any) => ({
  type: ActionTypesEnum.CHANGE_FRAUDS_FILTERED_QUANTITY_BY_STATE,
  payload: fraudsQuantityByState,
})

export const changeFraudsTotalPageByState = (fraudsTotalPagesByState: any) => ({
  type: ActionTypesEnum.CHANGE_FRAUDS_TOTAL_PAGES_BY_STATE,
  payload: fraudsTotalPagesByState,
})

export const changeFraudsFormError = (error: any) => ({
  type: ActionTypesEnum.CHANGE_FORM_ERROR,
  payload: error,
})

export const removeFormLinkOccurrence = () => ({
  type: ActionTypesEnum.REMOVE_FORM_LINK_OCCURRENCE,
})

export const changeSiteForm = (form: IThreatSiteForm) => ({
  type: ActionTypesEnum.CHANGE_SITE_FORM,
  payload: form,
})

export const changeAdsForm = (form: IThreatAdsForm) => ({
  type: ActionTypesEnum.CHANGE_ADS_FORM,
  payload: form,
})

export const changeProfileForm = (form: IThreatProfileForm) => ({
  type: ActionTypesEnum.CHANGE_PROFILE_FORM,
  payload: form,
})

export const changeContentForm = (form: IThreatContentForm) => ({
  type: ActionTypesEnum.CHANGE_CONTENT_FORM,
  payload: form,
})

export const getTakedowns = (filterTakedown: ITakedownsFilter | any) => ({
  type: ActionTypesEnum.GET_TAKEDOWNS,
  payload: filterTakedown,
})

export const changeTakedownsList = (list: ITakedowns[]) => ({
  type: ActionTypesEnum.CHANGE_TAKEDOWN_LIST,
  payload: list
})

export const changeTakedownsTable = (table: ITakedownsTable[]) => ({
  type: ActionTypesEnum.CHANGE_TABLE_TAKEDOWNS,
  payload: table
})

export const changeTakedownsCurrentIds = (ids: string[]) => ({
  type: ActionTypesEnum.CHANGE_CURRENT_IDS_TAKEDOWNS,
  payload: ids
})

export const changeTakedownsCurrent = (takedown: ITakedowns | null) => ({
  type: ActionTypesEnum.CHANGE_CURRENT_TAKEDOWN,
  payload: takedown
})

export const updateTakedown = (updatedTakedowns: ITakedowns) => ({
  type: ActionTypesEnum.UPDATE_TAKEDOWN,
  payload: updatedTakedowns,
});

export const changeTakedownsFilteredQuantityByPhase = (takedownsQuantityByState: any) => ({
  type: ActionTypesEnum.CHANGE_TAKEDOWNS_FILTERED_QUANTITY_BY_STATE,
  payload: takedownsQuantityByState,
})

export const changeTakedownsFilter = (takedownsFilter: any) => ({
  type: ActionTypesEnum.CHANGE_TAKEDOWNS_FILTER,
  payload: takedownsFilter,
})

export const changeTakedownsQuantityByPhase = (takedownsQuantityByPhase: any) => ({
  type: ActionTypesEnum.CHANGE_TAKEDOWNS_QUANTITY_BY_PHASE,
  payload: takedownsQuantityByPhase,
})

export const changeTakedownsFilteredQuantityByState = (takedownsQuantityByPhase: any) => ({
  type: ActionTypesEnum.CHANGE_TAKEDOWNS_FILTERED_QUANTITY_BY_PHASE,
  payload: takedownsQuantityByPhase,
})

export const changeTakedownsTotalPageByPhase = (takedownsTotalPagesByPhase: any) => ({
  type: ActionTypesEnum.CHANGE_TANKEDOWNS_TOTAL_PAGES_BY_PHASE,
  payload: takedownsTotalPagesByPhase,
})

export const updateTakedownFromSaga = (updatedTakedown: ITakedowns) => ({
  type: ActionTypesEnum.UPDATE_TAKEDOWN_FROM_SAGA,
  payload: updatedTakedown,
});

export const updateAssumeTakedownFromSaga = (updatedAssumeTakedown: any) => ({
  type: ActionTypesEnum.UPDATE_ASSUME_TAKEDOWN_FROM_SAGA,
  payload: updatedAssumeTakedown,
});

export const updateAssumeTakedown = (takedowns: string[]) => ({
  type: ActionTypesEnum.UPDATE_ASSUME_TAKEDOWN,
  payload: takedowns,
});

export const renounceTakedown = (takedowns: string[]) => ({
  type: ActionTypesEnum.UPDATE_RENOUNCE_TAKEDOWN,
  payload: takedowns,
});

export const changeTakedownsFromSaga = (updatedTakedowns: ITakedowns[]) => ({
  type: ActionTypesEnum.CHANGE_TAKEDOWNS_FROM_SAGA,
  payload: updatedTakedowns,
});

export const changeFilterTextTakedown = (text: string) => ({
  type: ActionTypesEnum.CHANGE_FILTER_TEXT_TAKEDOWN,
  payload: text,
});

export const changeFilterCompanyIdTakedown = (companyId: string) => ({
  type: ActionTypesEnum.CHANGE_FILTER_COMPANY_ID_TAKEDOWN,
  payload: companyId,
});

export const changeFilterTypeTakedown = (threatType: ThreatTypeEnum) => ({
  type: ActionTypesEnum.CHANGE_FILTER_TYPE_TAKEDOWN,
  payload: threatType,
});


export const changeFilterPlatformTakedown = (platform: ThreatPlatformEnum) => ({
  type: ActionTypesEnum.CHANGE_FILTER_PLATFORM_TAKEDOWN,
  payload: platform,
});

export const changeFilterStatusTakedown = (status: boolean) => ({
  type: ActionTypesEnum.CHANGE_FILTER_STATUS_TAKEDOWN,
  payload: status,
});

export const changeFilterSlaTakedown = (sla: TakedownSlaEnum) => ({
  type: ActionTypesEnum.CHANGE_FILTER_SLA_TAKEDOWN,
  payload: sla,
});

export const changeFilterIdentifiedTakedown = (text: string | null) => ({
  type: ActionTypesEnum.CHANGE_FILTER_IDENTIFIED_TAKEDOWN,
  payload: text,
});

export const changeFilterTimePhaseTakedown = (text: string | null) => ({
  type: ActionTypesEnum.CHANGE_FILTER_TIME_PHASE_TAKEDOWN,
  payload: text,
});

export const changeFilterIdentifiedHoursTakedown = (text: string | null) => ({
  type: ActionTypesEnum.CHANGE_FILTER_IDENTIFIED_HOURS_TAKEDOWN,
  payload: text,
});

export const changeFilterTimePhaseHoursTakedown = (text: string | null) => ({
  type: ActionTypesEnum.CHANGE_FILTER_TIME_PHASE_HOURS_TAKEDOWN,
  payload: text,
});

export const changeFilterNotificationsTakedown = (number: number | null) => ({
  type: ActionTypesEnum.CHANGE_FILTER_NOTIFICATIONS_TAKEDOWN,
  payload: number,
});

export const changeComplaintFormChannel = (channel: 'Formulário' | 'E-mail') => ({
  type: ActionTypesEnum.CHANGE_COMPLAINT_FORM_CHANNEL,
  payload: channel,
});

export const changeComplaintFormDate = (date: string) => ({
  type: ActionTypesEnum.CHANGE_COMPLAINT_FORM_DATE,
  payload: date,
});

export const changeComplaintFormReportedTo = (companyReported: CompaniesComplaintEnum) => ({
  type: ActionTypesEnum.CHANGE_COMPLAINT_FORM_REPORTED_TO,
  payload: companyReported,
});

export const changeComplaintFormReportedId = (reportedId: string) => ({
  type: ActionTypesEnum.CHANGE_COMPLAINT_FORM_REPORT_ID,
  payload: reportedId,
});

export const changeComplaintFormEvidenceLink = (evidenceLink: string) => ({
  type: ActionTypesEnum.CHANGE_COMPLAINT_FORM_EVIDENCE_LINK,
  payload: evidenceLink,
});

export const changeComplaintFormEvidence = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_COMPLAINT_FORM_EVIDENCE,
  payload: files,
});

export const registerComplaint = (complaint: ITakedownComplaintForm) => ({
  type: ActionTypesEnum.REGISTER_COMPLAINT,
  payload: complaint,
});

export const addComplaint = (complaint: ITakedownComplaintForm) => ({
  type: ActionTypesEnum.ADD_COMPLAINT,
  payload: complaint,
});

export const fetchComplaints = (ticketId: string) => ({
  type: ActionTypesEnum.FETCH_COMPLAINTS,
  payload: ticketId,
});

export const changeCurrentComplaints = (complaints: ITakedownComplaintForm[]) => ({
  type: ActionTypesEnum.CHANGE_CURRENT_COMPLAINTS,
  payload: complaints,
});

export const updateComplaint = (complaint: ITakedownComplaintForm) => ({
  type: ActionTypesEnum.UPDATE_COMPLAINT,
  payload: complaint,
});


export const addPix = (pix: ITakedownPixForm) => ({
  type: ActionTypesEnum.ADD_PIX,
  payload: pix,
});

export const fetchPix = (threatId: string) => ({
  type: ActionTypesEnum.FETCH_PIX,
  payload: threatId,
});

export const changeCurrentPix = (pix: ITakedownPixForm[]) => ({
  type: ActionTypesEnum.CHANGE_CURRENT_PIX,
  payload: pix,
});

export const updatePix = (pix: ITakedownPixForm) => ({
  type: ActionTypesEnum.UPDATE_PIX,
  payload: pix,
});

export const changePixFormEvidenceLink = (evidenceLink: string) => ({
  type: ActionTypesEnum.CHANGE_PIX_FORM_EVIDENCE_LINK,
  payload: evidenceLink,
});

export const changePixFormEvidence = (files: File[]) => ({
  type: ActionTypesEnum.CHANGE_PIX_FORM_EVIDENCE,
  payload: files,
});

export const changePixFormCheckoutUrl = (checkoutUrl: string) => ({
  type: ActionTypesEnum.CHANGE_PIX_FORM_CHECKOUT_URL,
  payload: checkoutUrl,
});

export const changePixFormCpf = (cpf: string) => ({
  type: ActionTypesEnum.CHANGE_PIX_FORM_CPF,
  payload: cpf,
});

export const changePixFormFinancialInstitution = (financialInstitutions: FinancialInstitutionEnum) => ({
  type: ActionTypesEnum.CHANGE_PIX_FORM_FINANCIAL_INSTITUTION,
  payload: financialInstitutions,
});

export const changePixFormPaymentGateway = (paymentGateway: PaymentsGatewayEnum) => ({
  type: ActionTypesEnum.CHANGE_PIX_FORM_PAYMENT_GATEWAY,
  payload: paymentGateway,
});

export const changePixFormPix = (pix: string) => ({
  type: ActionTypesEnum.CHANGE_PIX_FORM_PIX,
  payload: pix,
});

export const registerPix = (pix: ITakedownPixForm) => ({
  type: ActionTypesEnum.REGISTER_PIX,
  payload: pix,
});

export const changeReasoningFinishForm = (dto: { text: string, id: string }) => ({
  type: ActionTypesEnum.CHANGE_REASONING_FINISH_FORM,
  payload: dto,
});

export const finishTakedowns = (data: { takedowns: string[], reasoning: { text: string, id: string } }) => ({
  type: ActionTypesEnum.FINISH_TAKEDOWNS,
  payload: data,
});

export const specialTreatmentTakedowns = (takedowns: string[]) => ({
  type: ActionTypesEnum.SPECIAL_TREATMENT,
  payload: takedowns,
});

export const reopenThreats = (takedowns: string[]) => ({
  type: ActionTypesEnum.REOPEN_THREATS,
  payload: takedowns,
});

export const addTakedown = (takedown: ITakedowns) => ({
  type: ActionTypesEnum.ADD_TAKEDOWN,
  payload: takedown,
});

export const getThreatDetails = (ticketId: string) => ({
  type: ActionTypesEnum.FETCH_THREAT_DETAILS,
  payload: ticketId,
});

export const changeCurrentThreatOccurrencesFromSaga = (occurrences: any[]) => ({
  type: ActionTypesEnum.CHANGE_CURRENT_THREAT_OCCURRENCES,
  payload: occurrences,
});

export const addOccurrenceAdsForm = () => ({
    type: ActionTypesEnum.ADD_OCCURRENCE_ADS_FORM,
});

export const removeOccurrenceAdsForm = (index: number) => ({
    type: ActionTypesEnum.REMOVE_OCCURRENCE_ADS_FORM,
    payload: index,
});

export const changeAdsFormOccurence = (data: {updatedOccurrence: IThreatAdsForm, index: number}) => ({
    type: ActionTypesEnum.CHANGE_OCCURRENCE_ADS_FORM,
    payload: data,
});

export const addOccurrenceContentForm = () => ({
    type: ActionTypesEnum.ADD_OCCURRENCE_CONTENT_FORM,
});

export const removeOccurrenceContentForm = (index: number) => ({
    type: ActionTypesEnum.REMOVE_OCCURRENCE_CONTENT_FORM,
    payload: index,
});

export const changeOccurenceContentForm = (data: {updatedOccurrence: IThreatContentForm, index: number}) => ({
    type: ActionTypesEnum.CHANGE_OCCURRENCE_CONTENT_FORM,
    payload: data,
});

export const addOccurrenceProfileForm = () => ({
    type: ActionTypesEnum.ADD_OCCURRENCE_PROFILE_FORM,
});

export const removeOccurrenceProfileForm = (index: number) => ({
    type: ActionTypesEnum.REMOVE_OCCURRENCE_PROFILE_FORM,
    payload: index,
});

export const changeOccurenceProfileForm = (data: {updatedOccurrence: IThreatProfileForm, index: number}) => ({
    type: ActionTypesEnum.CHANGE_OCCURRENCE_PROFILE_FORM,
    payload: data,
});

export const addOccurrenceSiteForm = () => ({
    type: ActionTypesEnum.ADD_OCCURRENCE_SITE_FORM,
});

export const removeOccurrenceSiteForm = (index: number) => ({
    type: ActionTypesEnum.REMOVE_OCCURRENCE_SITE_FORM,
    payload: index,
});

export const changeOccurenceSiteForm = (data: {updatedOccurrence: IThreatSiteForm, index: number}) => ({
    type: ActionTypesEnum.CHANGE_OCCURRENCE_SITE_FORM,
    payload: data,
});

export const fetchReasoningLabels = () => ({
  type: ActionTypesEnum.FETCH_REASONING_LABELS,
});

export const changeReasoningLabelsFromSaga = (labels: any[]) => ({
  type: ActionTypesEnum.CHANGE_REASONING_LABELS_SAGA,
  payload: labels
});

export const linkThreatOccurrences = (dto: {occurrences: any[], threatId:string, type: ThreatTypeEnum}) => ({
  type: ActionTypesEnum.LINK_THREAT_OCCURRENCES,
  payload: dto
});