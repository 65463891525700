import styled from "styled-components";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import Accordion from "../../../components/Accordion";
import Label from "../../../components/Text/Label";
import Icon from "../../../components/Icon";
import useCanPerformAction from "../../../hooks/CanPerformAction.hook";
import Modal, { IFooterButton } from "../../../components/Modal";
import { AccessTime, Cancel, Check, EditOutlined, WebAssetOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { dsVariables } from "../../../variables";
import { getThreatTypeText } from "../../../../DesignSystem/utils/get-threat-type-text.util";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import { ActionEnum } from "../../../constants/user-action-permissions.constant";
import useIsEnabledOrTrial from "../../../hooks/IsEnabledOrTrial.hook";

interface IProps {
    isOpen: boolean;
    onClose: any;
    tabActive: number;
    ModalDiscard: () => void;
    ModalEdit: () => void;
    ModalQuarentine: () => void;
    ModalApproval: () => void;
    ModalCancelApproval: () => void;
    ModalTakedown: () => void;
    ModalRestore: () => void;
    ModalResolved: () => void;
}

export default function ModalDetailsThreat({
    isOpen,
    onClose,
    tabActive,
    ModalDiscard,
    ModalResolved,
    ModalEdit,
    ModalQuarentine,
    ModalApproval,
    ModalCancelApproval,
    ModalTakedown,
    ModalRestore
}: IProps) {

    const { threatsTableData, current, isCustomer } = useSelector((store: IStore) => ({
        threatsTableData: store.Threats.threatsTableData,
        current: store.Threats.current,
        isCustomer: store.Profile.user.isCustomer,
    }))

    type Evidence = File | string | { screenshotUrl: string };

    const { isTrial } = useIsEnabledOrTrial("fraud")

    const showApproveTakedown = useCanPerformAction(ActionEnum.approve_takedown)
    const showDiscardThreat = useCanPerformAction(ActionEnum.discard_threat)
    const showQuarantine = useCanPerformAction(ActionEnum.move_threat_to_quarantine)
    const showResolved = useCanPerformAction(ActionEnum.mark_threat_as_resolved)
    const showRestore = useCanPerformAction(ActionEnum.restore_threat_from_discarded)
    const showEdit = useCanPerformAction(ActionEnum.edit_threat)
    const showCancelApproval = useCanPerformAction(ActionEnum.cancel_approval_request)
    const showRequestTakedown = useCanPerformAction(ActionEnum.request_takedown_approval)

    function canShowOptions() {
        if (isCustomer) return !showApproveTakedown && !showDiscardThreat && !showQuarantine && !isTrial

        return tabActive == 3
            ? !showRestore
            : !showRestore &&
            !showDiscardThreat &&
            !showQuarantine &&
            !showResolved &&
            !showRestore &&
            !showEdit &&
            !showCancelApproval
    }

    const getFooterButtons = (): IFooterButton[] => {
        const actions: IFooterButton[] = []
        if (tabActive == 4 || tabActive == 5) return actions

        if (isCustomer) {
            if (tabActive != 1 && showQuarantine) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalQuarentine, label: 'Quarentena', iconLeft: WarningAmberOutlinedIcon, iconLeftColor: dsVariables.colors.Astronaut[900] })
            }
            if (tabActive != 3 && showDiscardThreat) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalDiscard, label: 'Descartar', iconLeft: DeleteOutlinedIcon, iconLeftColor: dsVariables.colors.Red[400], type: 'destructiveBorderless' })
            }
            if (tabActive === 2 && showApproveTakedown) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalTakedown, label: 'Aprovar Takedown', iconLeft: WebAssetOff, iconLeftColor: 'white' })
            }
            if (tabActive != 2 && tabActive != 4 && showRequestTakedown) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalTakedown, label: 'Solicitar Takedown', iconLeft: WebAssetOff, iconLeftColor: 'white' })
            }
        } else {
            if ((tabActive == 0 || !tabActive) && showQuarantine) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalQuarentine, label: 'Quarentena', iconLeft: AccessTime, iconLeftColor: dsVariables.colors.Astronaut[900], type: 'normal' })
            }
            if ((tabActive == 0 || !tabActive || tabActive == 1) && showRequestTakedown) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalTakedown, label: 'Solicitar Aprovaçao', iconLeft: WebAssetOff, iconLeftColor: 'white' })
            }
            if (tabActive === 2 && showApproveTakedown) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalApproval, label: 'Aprovar takedown', iconLeft: WebAssetOff, iconLeftColor: 'white' })
            }
            if (tabActive == 2 && showCancelApproval) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalCancelApproval, label: 'Cancelar solicitação de aprovação', iconLeft: Cancel, iconLeftColor: dsVariables.colors.Astronaut[900], type: 'normal' })
            }
            if (tabActive != 3 && showDiscardThreat) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalDiscard, label: 'Descartar', iconLeft: DeleteOutlinedIcon, iconLeftColor: dsVariables.colors.Red[400], type: 'destructiveBorderless' })
            }
            if (tabActive == 3 && showRestore) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalRestore, label: 'Restaurar', iconLeft: HistoryOutlinedIcon, iconLeftColor: 'white' })
            }
            if (tabActive == 4 && showResolved) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalResolved, label: 'Marcar como resolvida', iconLeft: Check, iconLeftColor: dsVariables.colors.Astronaut[900], type: 'normal' })
            }
            if ((tabActive == 0 || !tabActive || tabActive == 1 || tabActive == 4) && showEdit) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalEdit, label: 'Editar', iconLeft: EditOutlined, iconLeftColor: dsVariables.colors.Astronaut[900], type: 'normal' })
            }


        }
        return actions;
    };

    function getAccordionBorderType(array: any[], index: number): 'top' | 'bottom' | 'top-bottom' | any {
        if (array.length == 1 || array.length == 0) return

        if (array.length == 2) return index == 0 ? 'bottom' : 'top'
        else {
            if (index == 0) return 'bottom'
            else if (index == array.length - 1) return 'top'
            else return 'top-bottom'
        }
    }

    return (
        <>
            <Modal
                title="Detalhes da ameaça" isOpen={isOpen} onClose={onClose}
                width={'90%'}
                footerButtons={getFooterButtons()}
            >
                {threatsTableData?.filter(frauds => frauds._id === current?._id)
                    .map((fraud) => (
                        <div key={fraud._id}>
                            <div className="d-flex justify-content-between" style={{ marginBottom: 24 }}>
                                <div>
                                    <div style={{ marginBottom: 50 }}>
                                        <a href={fraud.url} target="blank" style={{ fontSize: 14, textDecoration: "underline", color: dsVariables.colors.Blue[400] }}>
                                            {fraud.url}
                                        </a>
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                        <P>Tipo de Ameaça:</P>
                                        <P2>{getThreatTypeText(fraud.type)}</P2>
                                    </div>
                                    {getThreatTypeText(fraud.type) === "Site falso" ? "" :
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Plataforma:</P>
                                            <P2>{fraud.platform}</P2>
                                        </div>
                                    }
                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                        <P>Status:</P>
                                        <P2>{fraud.isActive ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE}</P2>
                                    </div>
                                    {getThreatTypeText(fraud.type) === "Anúncio falso" ?
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Veiculação iniciada em:</P>
                                            <P2>—</P2>
                                        </div>
                                        : ""}
                                    {getThreatTypeText(fraud.type) === "Site falso" || getThreatTypeText(fraud.type) === "Conteúdo falso" ? "" :
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Data da criação:</P>
                                            <P2>{new Date(fraud.createdAt).toLocaleDateString("pt-BR")}</P2>
                                        </div>
                                    }
                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                        <P>Data de identificação:</P>
                                        <P2>{new Date(fraud.createdAt).toLocaleDateString("pt-BR")}</P2>
                                    </div>
                                    {getThreatTypeText(fraud.type) === "Anúncio falso" ?
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>ID do anúncio:</P>
                                            <P2>{fraud.advertiserId ?? "—"}</P2>
                                        </div>
                                        : ""}
                                    {getThreatTypeText(fraud.type) === "Site falso" ? "" :
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Perfil:</P>
                                            <P2></P2>
                                        </div>
                                    }
                                </div>

                                <div style={{ marginTop: 72, width: 425 }}>
                                    {/* <P style={{ marginBottom: 12 }}>Tipo de Ameaça:</P> */}
                                    <div className="d-flex flex-column gap-2">
                                        {fraud.evidence?.map((file: Evidence, index) => {
                                            const fileUrl =
                                                typeof file === 'string' ? file :
                                                    file instanceof File ? URL.createObjectURL(file) :
                                                        file.screenshotUrl;

                                            return (
                                                <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                                    <div className="d-flex gap-2 align-items-center">
                                                        {/* @ts-ignore */}
                                                        <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                                        <div className="d-flex flex-column gap-1">
                                                            <Label>{`Evidência ${++index}`}</Label>
                                                        </div>
                                                    </div>
                                                    <button className="border-0 bg-transparent">
                                                        <a href={fileUrl} target="blank" >
                                                            <Icon IconComponent={OpenInNewIcon} color={dsVariables.colors.Astronaut[700]} />
                                                        </a>
                                                    </button>
                                                </FilesCard>
                                            )
                                        })}

                                    </div>
                                </div>

                            </div>

                            {/* accordeon de anúncio falso */}

                            {getThreatTypeText(fraud.type) === "Anúncio falso" ?
                                <Accordion title="Informação do Anunciante">
                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                        <P>Perfil Anunciante:</P>
                                        <P2></P2>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                        <P>ID Perfil Facebook:</P>
                                        <P2></P2>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                        <P>Perfil Instagram:</P>
                                        <P2>{fraud.instagramProfile ?? "—"}</P2>
                                    </div>
                                </Accordion>
                                : ""}

                            {/* accordeon de site falso */}

                            {getThreatTypeText(fraud.type) === "Site falso" ?
                                <>
                                    <Accordion title="Informações do Domínio">
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Domain Name:</P>
                                            <P2></P2>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Register Date UTC:</P>
                                            <P2>
                                                {fraud.threatDetails?.domainInfo?.createdDate ? new Date(fraud.threatDetails?.domainInfo?.createdDate).toLocaleDateString("pt-BR") : "—"}
                                            </P2>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Register Domain ID:</P>
                                            <P2>{fraud.threatDetails?.domainInfo?.id ?? "—"}</P2>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Status:</P>
                                            <P2>{fraud.threatDetails?.domainInfo?.status ?
                                                fraud.threatDetails?.domainInfo?.status.map((status: any, index: any) => (
                                                    <>
                                                        <span key={index}>{status}</span><br />
                                                    </>
                                                )) : "—"
                                            }
                                            </P2>
                                        </div>
                                    </Accordion>
                                    <Accordion title="Informações do Hospedagem">
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>IANA Number:</P>
                                            <P2></P2>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Entity:</P>
                                            <P2>{fraud.threatDetails?.hostInfo?.slice(-1)[0]?.abuseName ?? "—"}</P2>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Abuse Contact Phone:</P>
                                            <P2></P2>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Abuse Contact Email:</P>
                                            <P2>{fraud.threatDetails?.hostInfo?.slice(-1)[0]?.abuseEmail ?? "—"}</P2>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Entity Website:</P>
                                            <P2></P2>
                                        </div>
                                    </Accordion>
                                    <Accordion title="Informações do Registrar">
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Net Range:</P>
                                            <P2>{fraud.threatDetails?.hostInfo?.slice(-1)[0]?.ipRange ?? "—"}</P2>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Net Name:</P>
                                            <P2>{fraud.threatDetails?.hostInfo?.slice(-1)[0]?.networkName ?? "—"}</P2>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: 16 }}>
                                            <P>Abuse Contact Email:</P>
                                            <P2>{fraud.threatDetails?.hostInfo?.slice(-1)[0]?.abuseEmail ?? "—"}</P2>
                                        </div>
                                    </Accordion>
                                </>
                                : ""}

                            {/* accordeon de site falso */}

                            <Accordion title="Ocorrências">
                                <div className="d-flex flex-column">
                                    {
                                        current?.occurrence?.map((occurrence: any, index: number) =>
                                            <Accordion key={index}
                                                isChild
                                                borderType={getAccordionBorderType(current?.occurrence, index)}
                                                withBorder
                                                title={`${index + 1}. ${occurrence.url}`}>
                                                <div key={index} className="d-flex flex-column gap-2">
                                                    <div className="d-flex gap-4">
                                                        <Label>Evidência(s):</Label>
                                                        <P2 className="flex-grow-1 gap-3 d-flex flex-column">{occurrence.evidence.link
                                                            ? occurrence.evidence.link
                                                            : occurrence.evidence?.files?.map((file: string, index: number) => {
                                                                return (
                                                                    <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                                                        <div className="d-flex gap-2 align-items-center">
                                                                            {/* @ts-ignore */}
                                                                            <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                                                            <div className="d-flex flex-column gap-1">
                                                                                <Label>{`Evidência ${++index}`}</Label>
                                                                            </div>
                                                                        </div>
                                                                        <button className="border-0 bg-transparent">
                                                                            <a href={file} target="blank" >
                                                                                <Icon IconComponent={OpenInNewIcon} color={dsVariables.colors.Astronaut[700]} />
                                                                            </a>
                                                                        </button>
                                                                    </FilesCard>
                                                                )
                                                            })}
                                                        </P2>
                                                    </div>
                                                </div>
                                            </Accordion>)
                                    }
                                    {
                                        current?.pixOccurrences?.map((pixOccurrence: any, index: number) =>
                                            <Accordion key={index}
                                                isChild
                                                borderType={getAccordionBorderType(current?.pixOccurrences!, index)}
                                                withBorder
                                                title={`${current.occurrence.length + (index + 1)}. ${pixOccurrence.pix}`}>
                                                <div key={index} className="d-flex flex-column gap-2">
                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">CPF:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.cpf}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Instituição financeira:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.financialInstitution}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Url de checkout:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.checkoutUrl}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Gateway de pagamento:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.paymentGateway}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Evidência(s):</Label>
                                                        </div>
                                                        <P2 className="flex-grow-1 gap-3 d-flex flex-column">{pixOccurrence.evidence.link
                                                            ? pixOccurrence.evidence.link
                                                            : pixOccurrence.evidence?.files?.map((file: string, index: number) => {
                                                                return (
                                                                    <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                                                        <div className="d-flex gap-2 align-items-center">
                                                                            {/* @ts-ignore */}
                                                                            <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                                                            <div className="d-flex flex-column gap-1">
                                                                                <Label>{`Evidência ${++index}`}</Label>
                                                                            </div>
                                                                        </div>
                                                                        <button className="border-0 bg-transparent">
                                                                            <a href={file} target="blank" >
                                                                                <Icon IconComponent={OpenInNewIcon} color={dsVariables.colors.Astronaut[700]} />
                                                                            </a>
                                                                        </button>
                                                                    </FilesCard>
                                                                )
                                                            })}
                                                        </P2>
                                                    </div>
                                                </div>
                                            </Accordion>
                                        )
                                    }
                                </div>

                            </Accordion>
                        </div>
                    ))}
            </Modal>
        </>

    );
}

const P = styled.p`
    margin-bottom: 0;
    font-size: 16px;
    min-width: 200px
`;

const P2 = styled.p`
    margin-bottom: 0;
    font-size: 16px;
`;

const FilesCard = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
`
