import styled from "styled-components"
import { dsVariables } from "../../../variables"
import SelectFilterSection from "../../../components/FilterSection/SelectFilterSection"
import ButtonTertiary from "../../../components/Button/ButtonTertiary"
import { useEffect } from "react";
import { DeleteOutline, Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { changeFilterManageUsers, fetchUsersAndInviteds } from "../../../../store/admin/action";
import { IStore } from "../../../../interfaces/store.interface";
import Input from "../../../components/Input/Input";
import Label from "../../../components/Text/Label";

export function getFilterRegistered(value: string) {
    return value !== 'Todos' ? value == 'Sim' : null;
}
export function getFilterStatus(value: string) {
    return value !== 'Todos' ? value == 'Ativo' : null;
}

export default function Filter() {

    const dispatch = useDispatch()

    const { filter } = useSelector((store: IStore) => store.Admin.usersAndInviteds)

    const isCleanButtonDisabled = filter.registered == 'Todos' && filter.userStatus == 'Todos' && !filter.text

    function changeRegistered(e: any) {
        dispatch(changeFilterManageUsers({ ...filter, registered: e.target.value }))
    }

    function changeStatus(e: any) {
        dispatch(changeFilterManageUsers({ ...filter, userStatus: e.target.value }))
    }

    function clearFilter() {
        // essa verificação serve para listar os usuários novamente, pois o useEffect 
        // não será chamado se esses dois dados não forem alterados
        if (filter.registered == 'Todos' && filter.userStatus == 'Todos')
            dispatch(fetchUsersAndInviteds({ page: 1, quantity: 10, }))

        dispatch(changeFilterManageUsers({
            registered: 'Todos',
            userStatus: 'Todos',
        }))
    }

    function changeText(e: any) {
        dispatch(changeFilterManageUsers({ ...filter, text: e.target.value }))
    }

    function handleFilterText() {
        dispatch(fetchUsersAndInviteds({
            page: 1, quantity: 10,
            registered: getFilterRegistered(filter.registered),
            status: getFilterStatus(filter.userStatus),
            text: filter.text
        }))
    }

    useEffect(() => {
        dispatch(fetchUsersAndInviteds({
            page: 1, quantity: 10, text: filter.text,
            registered: getFilterRegistered(filter.registered),
            status: getFilterStatus(filter.userStatus)
        }))
    }, [filter.registered, filter.userStatus]);

    return (
        <Section className="bg-white my-4 d-flex gap-3 align-items-md-end flex-md-row flex-column">
            <div className="d-flex flex-column gap-2 flex-grow-1">
                <Label style="medium">Usuário</Label>
                <Input onClickIconRight={handleFilterText} iconRight={Search} value={filter.text} placeholder="Pesquisar usuário" onChange={changeText} />
            </div>
            <SelectFilterSection value={filter.registered} label="Cadastrado" onChange={changeRegistered} options={['Todos', 'Sim', 'Não']} />
            <SelectFilterSection value={filter.userStatus} label="Status" onChange={changeStatus} options={['Todos', 'Ativo', 'Inativo']} />
            <ButtonTertiary state={isCleanButtonDisabled ? 'disabled' : 'default'} iconLeftColor={dsVariables.colors.Red[700]} iconLeft={DeleteOutline} type="destructive" onClick={clearFilter} label="Limpar" />
        </Section>
    )
}

const Section = styled.section`
    padding: ${dsVariables.spacing[16]};
    border-radius: ${dsVariables.cornerRadius[4]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
    ${dsVariables.shadows.Down.Blur2}
    
    @media (max-width: 777px) {
        max-width: 100%;

        div:nth-of-type(n+2),
        div > select  {
            width: 100%;
        }
    }
`