import React, { useEffect, useState } from "react";
import { formatBarDate } from "../../utils/dateFormat";
import moment from "moment";
import "moment/locale/pt-br";
import { useDispatch, useSelector } from "react-redux";
import { changeListAllFetchOffendersGroupByDomain } from "../../store/pages/analytical/actions";
moment.locale("pt-BR");
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
}

function handleTogglePopover(event) {
  let divPopover = document.getElementsByClassName("chart-popover")[0];

  if (!event) {
    divPopover.style.visibility = "hidden";
    divPopover.style.opacity = 0;
    return;
  }

  divPopover.style.transform = "translate(15px, 0)";
  divPopover.style.visibility = "visible";
  divPopover.style.opacity = 1;
  divPopover.style.transition = ".10s ease all";

  event.currentTarget.onmousemove = (e) => {
    divPopover.style.left = e.clientX + "px";
    divPopover.style.top = e.clientY + 8 + "px";
  };
}

function serChartColorsRange(rgb) {
  const maxColors = 20;
  const intervalIndex = 0.5;
  const intervalColor = 0.05;
  let colorsRange = [];
  let minIndex = 0;
  let maxIndex = 0;
  let color = 0;

  for (var i = 0; i < maxColors; i++) {
    const isInitialValue = i === 0;

    minIndex = isInitialValue ? 0 : minIndex + intervalIndex;
    maxIndex = isInitialValue ? intervalIndex : maxIndex + intervalIndex;
    color = isInitialValue ? 0 : color + intervalColor;

    if (minIndex === intervalIndex) {
      minIndex = minIndex + 0.01;
    }

    colorsRange.push({
      bgColor: `rgba(${rgb}, ${color.toFixed(2)})`,
      min: minIndex,
      max: maxIndex,
    });
  }

  return colorsRange;
}

const HeatmapChart = ({
  rangeTitle,
  labelX,
  series,
  color,
  onChangeAccordion,
  onToggleModal,
  handleAds,
  selectedCampaign,
}) => {
  const [popoverData, setPopoverData] = useState({});
  const [isAccordionOpened, setIsAccordionOpened] = useState(false);

  const chartHeatmapColor = hexToRgb(color);
  const rgb = `${chartHeatmapColor.r}, ${chartHeatmapColor.g}, ${chartHeatmapColor.b}`;
  const chartColorsRange = serChartColorsRange(rgb);

  const serieBgColor = (index) => {
    let bgColor;

    chartColorsRange.forEach((range) => {
      if (Math.round(index) <= range.max && Math.round(index) > range.min) {
        bgColor = range.bgColor;
      }
    });

    return bgColor;
  };

  const dispatch = useDispatch()
  const { listAll: listAllOffendersGroupByDomain } = useSelector(store => store.AnalyticalReport.offenderGroupByDomain)

  const toggleAccordion = (isOpened) => {
    dispatch(changeListAllFetchOffendersGroupByDomain(isOpened))
  };

  useEffect(() => {
    onChangeAccordion(listAllOffendersGroupByDomain);
    setIsAccordionOpened(listAllOffendersGroupByDomain);
  }, [listAllOffendersGroupByDomain]);

  return (
    <React.Fragment>
      <div className="heatmap-chart">
        <div className="heatmap-chart__header">
          <div className="heatmap-chart__header__range">
            <div className="chart-range">
              <span className="chart-range__title">{rangeTitle}</span>
              <div>
                <span>0.00</span>
                <div
                  style={{
                    background: `linear-gradient(260deg, rgba(${rgb},1) 25%, rgba(255,255,255,1) 100%)`,
                  }}
                ></div>
                <span>10.00</span>
              </div>
            </div>
          </div>

          <div className="heatmap-chart__header__label-x">
            {labelX.map((label, index) => (
              <div key={index} className="text-center position-relative">
                <div className="item"></div>
                {/* Usa moment para formatar cada data */}
                <div>{moment(label).format("DD MMM")}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="heatmap-chart__body">
          {series.map((serie, index) => {
            if (!serie.label.text) return null; // Verificação de null para serie.label.text, tratamento paliativo
            if (serie.label.text === "doubleclick.net") return null; // Verificação de doubleclick.net para serie.label.text, tratamento paliativo

            return (
              <div className="heatmap-chart__series" key={index}>
                <div className="heatmap-chart__label-y">
                  <span className="text-truncate">
                    <strong>{serie.label.index.toFixed(2)}</strong>
                    <span className="text-truncate ms-2 me-2">
                      {serie.label.text}
                    </span>
                  </span>

                  <i
                    className="bx bx-file-find fs-18 align-bottom me-1 link-secondary cursor-pointer"
                    onClick={() => {
                      onToggleModal(serie.label.data);
                      handleAds(selectedCampaign, serie.label.text);
                    }}
                  ></i>
                </div>

                <div className="heatmap-chart__series__grid">
                  {serie.data.map((item, index) => (
                    <div
                      className="border-top border-bottom border-white"
                      key={index}
                      onMouseEnter={(e) => {
                        handleTogglePopover(e);
                        setPopoverData(item);
                      }}
                      onMouseLeave={() => handleTogglePopover()}
                      style={{ backgroundColor: serieBgColor(item.index) }}
                    ></div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>

        <div className="heatmap-chart__footer">
          <div className="heatmap-chart__label-y">
            <div
              className="fw-semibold fs-12 cursor-pointer d-flex mt-3 text-decoration-underline"
              onClick={() => toggleAccordion(!isAccordionOpened)}
            >
              {isAccordionOpened ? "Exibir 10 itens" : "Exibir tudo"}
            </div>
          </div>
        </div>

        <div className="chart-popover">
          <div>{formatBarDate(popoverData.date)} </div>
          <div>
            <span style={{ backgroundColor: `rgba(${rgb}, 1.0)` }}></span>{" "}
            Agressividade:
            <strong>{popoverData.index}</strong>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeatmapChart;
