import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { useEffect, useState } from "react";
import { cancelUserInvite, changeErrorMessage, changeSuccessMessage, changeUserStatus, fetchUsersAndInviteds, resendUserInvite } from "../../../../store/admin/action";
import Icon from "../../../components/Icon";
import { CancelOutlined, Check, Close, ForwardToInboxOutlined, LockReset, Unsubscribe, UnsubscribeOutlined } from "@mui/icons-material";
import { dsVariables } from "../../../variables";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Switch from "../../../components/Switch";
import { Bounce, toast } from "react-toastify";
import Modal from "../../../components/Modal";
import ChangePasswordForm from "../forms/change-password-form";
import Paginator from "./paginator";
import Filter from "./filter";

export default function ManageUsers() {

    const dispatch = useDispatch()

    const { usersAndInviteds, successMessage, errorMessage } = useSelector((store: IStore) => store.Admin)

    const [modalIsOpened, setModalIsOpened] = useState(false);
    const [userIdToChangePassword, setUserIdToChangePassword] = useState('');

    useEffect(() => {
        if (successMessage) {
            toast.success(successMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                transition: Bounce,
            })
            dispatch(changeSuccessMessage(''))
        }
        if (errorMessage) {
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                transition: Bounce,
            })
            dispatch(changeErrorMessage(''))
        }
    }, [successMessage, errorMessage]);

    function handleUserStatus(email: string) {
        dispatch(changeUserStatus(email))
    }

    function cancelInvite(email: string) {
        dispatch(cancelUserInvite(email))
    }

    function resendInvite(email: string) {
        dispatch(resendUserInvite(email))
    }

    function handleResetPassword(userId: string) {
        setUserIdToChangePassword(userId)
        setModalIsOpened(true)
    }

    return (
        <section className="d-flex flex-column">
            <Filter />

            <div className="bg-white d-flex flex-column gap-3 rounded-2">
                <Table className="table">
                    <thead>
                        <tr className="fw-bold">
                            <td>Usuário</td>
                            <td>Cadastrado</td>
                            <td>Status</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        {usersAndInviteds.data?.map((el, index) =>
                            <tr key={index}>
                                <td style={{ maxWidth: 100 }}>{el.email}</td>
                                <td className="ps-5">{el?.userId
                                    ? <Icon color={dsVariables.colors.Green[400]} IconComponent={Check} />
                                    : <Icon color={dsVariables.colors.Red[400]} IconComponent={Close} />}
                                </td>
                                <td className="ps-3">{el?.userIsEnabled != null
                                    ? <Switch isOn={Boolean(el.userIsEnabled)} onChange={() => handleUserStatus(el.email)} />
                                    : null
                                }
                                </td>
                                {!el?.userId ?
                                    <td className="d-flex gap-3 ">
                                        <div role="button">
                                            <Tooltip text="Cancelar convite">
                                                <Icon onClick={() => cancelInvite(el.email)} color={dsVariables.colors.Red[300]} IconComponent={UnsubscribeOutlined} />
                                            </Tooltip>
                                        </div>
                                        <div role="button">
                                            <Tooltip text="Reenviar convite">
                                                <Icon onClick={() => resendInvite(el.email)} color={dsVariables.colors.AzureRadiance[300]} IconComponent={ForwardToInboxOutlined} />
                                            </Tooltip>
                                        </div>
                                    </td>
                                    : <td></td>
                                }
                            </tr>
                        )}
                    </tbody>
                </Table>

                <Paginator />
            </div>

            <Modal width={300} isOpen={modalIsOpened} onClose={() => setModalIsOpened(false)} title="Resetar senha" subtitle={`Usuário ${usersAndInviteds.data?.find(el => el.userId == userIdToChangePassword)?.email}`}>
                <ChangePasswordForm closeModal={() => setModalIsOpened(false)} userId={usersAndInviteds.data?.find(el => el.userId == userIdToChangePassword)?.userId} />
            </Modal>
        </section>
    )
}