import styled from "styled-components"
import { dsVariables } from "../../variables"
import SelectFilterSection from "./SelectFilterSection"
import { ThreatPlatformEnum } from "../../../enums/threat-platform.enum"
import { useSelector } from "react-redux"
import { IStore } from "../../../interfaces/store.interface"
import { ThreatStatusActiveEnum } from "../../../enums/threat-status-active.enum"
import { ThreatTypeTextEnum } from "../../../enums/threat-type-text.enum"
import { getThreatType } from "../../utils/get-threat-type.util"
import { useLocation, useNavigate } from "react-router-dom"
import { getThreatTypeText } from "../../utils/get-threat-type-text.util"
import ButtonTertiary from "../Button/ButtonTertiary"
import { DeleteOutline } from "@mui/icons-material"
import { ThreatTypeEnum } from "../../../enums/threat-type.enum"

export default function FilterSection() {

    const { filter } = useSelector((store: IStore) => store.Threats)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const isCleanButtonDisabled =
        searchParams.get('type') == ThreatTypeEnum.ALL &&
        searchParams.get('platform') == ThreatPlatformEnum.ALL &&
        !searchParams.get('status')

    function changeType(e: any) {
        searchParams.set('type', getThreatType(e.target.value))
        searchParams.set('page', '1');
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    function changePlatform(e: any) {
        searchParams.set('platform', e.target.value)
        searchParams.set('page', '1');
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    function changeStatus(e: any) {
        searchParams.set('status', e.target.value)
        searchParams.set('page', '1');
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    function clearFilter() {
        searchParams.set('type', ThreatTypeEnum.ALL);
        searchParams.set('platform', ThreatPlatformEnum.ALL);
        searchParams.delete('status');
        searchParams.delete('sla');

        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    return (
        <Section className={`position-absolute z-1 end-0 top-0 bg-white`}>
            <div className="d-flex flex-column gap-2">
                <div id="selects-filter-section" className="d-flex gap-3">
                    <SelectFilterSection value={getThreatTypeText(filter.type)} label="Tipo de ameaça" onChange={changeType} options={Object.values(ThreatTypeTextEnum)} />
                    <SelectFilterSection value={filter.platform} label="Plataforma" onChange={changePlatform} options={Object.values(ThreatPlatformEnum)} />
                    <SelectFilterSection value={searchParams.get('status')!} label="Status" onChange={changeStatus} options={['Todos'].concat(Object.values(ThreatStatusActiveEnum))} />
                </div>

                <div className="d-flex justify-content-end">
                    <ButtonTertiary state={isCleanButtonDisabled ? 'disabled' : 'default'} iconLeftColor={dsVariables.colors.Red[700]} iconLeft={DeleteOutline} type="destructive" onClick={clearFilter} label="Limpar" />
                </div>
            </div>
        </Section>
    )
}

const Section = styled.section`
    margin-top: 40px;
    width: 673px;
    padding: ${dsVariables.spacing[16]};
    border-radius: ${dsVariables.cornerRadius[4]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
    ${dsVariables.shadows.Down.Blur2}

    @media (max-width: 1111px) {
        left:0 !important;
        max-width: 240px;
        
        #selects-filter-section {
            flex-direction:column;
        }
    }
`