export enum PaymentsGatewayEnum {
    NONE = "Nenhum",
    PAGSEGURO = "PagSeguro",
    CIELO = "Cielo",
    PAYPAL = "PayPal",
    STONE = "Stone",
    MUNDIPAGG = "Mundipagg",
    EFI = "Efí",
    BEETECH = "BeeTech",
    PAGARME_PAGAMENTOS_SA = "Pagar.me Pagamentos S.A",
    IUGU = "iugu",
    NARWAL = "Narwal",
}