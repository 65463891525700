export enum CompaniesComplaintEnum {
    NONE = "Nenhum",
    GO_DADDY = "GoDaddy",
    BLUEHOST = "BlueHost",
    NAMECHEAP = "Namecheap",
    META_PLATFORMS_INC = "Meta Platforms, Inc.",
    HOSTGATOR = "HostGator",
    HOSTINGER = "Hostinger",
    GOOGLE_DOMAINS = "Google Domains",
    DYNADOT = "Dynadot",
    DREAM_HOST = "DreamHost",
    SITE_GROUND = "SiteGround",
}