import styled from "styled-components"
import { dsVariables } from "../../variables";
import { IButtonProps, TButtonStates, TButtonTypes } from "./ButtonPrimary";
import Icon from "../Icon";

export default function ButtonSecondary({ type = "normal", state = "default", label, iconLeft, iconRight, mode = "light", onClick,iconLeftColor,iconRightColor }: IButtonProps) {
    return (
        <Button state={state} type={type} onClick={onClick} className={`bg-transparent d-flex gap-2 align-items-center justify-content-center`}>
            {iconLeft && <Icon width={16} height={16} IconComponent={iconLeft} color={iconLeftColor} />}
            {label && <span>{label}</span>}
            {iconRight && <Icon width={16} height={16} IconComponent={iconRight}  color={iconRightColor}/>}
        </Button>
    )
}

const Button = styled.button<{ type: TButtonTypes, state: TButtonStates }>`
    padding: ${dsVariables.spacing[8]} ${dsVariables.spacing[12]};
    border-radius: ${dsVariables.spacing[4]};
    border: 1px solid;
    height:36px;

    ${props => {
        if (props.state == "disabled") return `
            color: ${dsVariables.colors.Gray[300]};
            border-color:${dsVariables.colors.Gray[300]};
        `
        switch (props.type) {
            case 'destructive':
                return `
                    color: ${dsVariables.colors.Red[700]};
                    boder-color: ${dsVariables.colors.Red[700]};
                `
            case 'destructiveBorderless':
                return `
                    background-color: ${dsVariables.colors.Red[700]};
                    color: ${dsVariables.colors.Red[700]};
                    border: none;
                `
            case 'warning':
                return `
                    color: ${dsVariables.colors.Orange[700]};
                    boder-color: ${dsVariables.colors.Orange[700]};
                `
            case 'normal':
            default:
                return `
                    color: ${dsVariables.colors.Astronaut[900]};
                    boder-color: ${dsVariables.colors.Astronaut[900]};
                `
        }
    }
    }
`