export const SET_ANALYTICAL_START_DATE = "SET_ANALYTICAL_START_DATE";
export const SET_ANALYTICAL_END_DATE = "SET_ANALYTICAL_END_DATE";

export const FETCH_DAILY_SCORES_REQUEST = "FETCH_DAILY_SCORES_REQUEST";
export const FETCH_DAILY_SCORES_SUCCESS = "FETCH_DAILY_SCORES_SUCCESS";
export const FETCH_DAILY_SCORES_FAILURE = "FETCH_DAILY_SCORES_FAILURE";

export const FETCH_DAILY_TOTAL_AGRESSORS_REQUEST =
  "FETCH_DAILY_TOTAL_AGRESSORS_REQUEST";
export const FETCH_DAILY_TOTAL_AGRESSORS_SUCCESS =
  "FETCH_DAILY_TOTAL_AGRESSORS_SUCCESS";
export const FETCH_DAILY_TOTAL_AGRESSORS_FAILURE =
  "FETCH_DAILY_TOTAL_AGRESSORS_FAILURE";

export const FETCH_ANALYTICAL_KPIS_REQUEST = "FETCH_ANALYTICAL_KPIS_REQUEST";
export const FETCH_ANALYTICAL_KPIS_SUCCESS = "FETCH_ANALYTICAL_KPIS_SUCCESS";
export const FETCH_ANALYTICAL_KPIS_FAILURE = "FETCH_ANALYTICAL_KPIS_FAILURE";

export const FETCH_OFFENDERS_GROUP_BY_DOMAIN_REQUEST =
  "FETCH_OFFENDERS_GROUP_BY_DOMAIN_REQUEST";
export const CHANGE_LIST_ALL_FETCH_OFFENDERS_GROUP_BY_DOMAIN =
  "CHANGE_LIST_ALL_FETCH_OFFENDERS_GROUP_BY_DOMAIN";
export const FETCH_OFFENDERS_GROUP_BY_DOMAIN_SUCCESS =
  "FETCH_OFFENDERS_GROUP_BY_DOMAIN_SUCCESS";
export const FETCH_OFFENDERS_GROUP_BY_DOMAIN_FAILURE =
  "FETCH_OFFENDERS_GROUP_BY_DOMAIN_FAILURE";

export const FETCH_OCCURRENCES_TREND_REQUEST =
  "FETCH_OCCURRENCES_TREND_REQUEST";
export const FETCH_OCCURRENCES_TREND_SUCCESS =
  "FETCH_OCCURRENCES_TREND_SUCCESS";
export const FETCH_OCCURRENCES_TREND_FAILURE =
  "FETCH_OCCURRENCES_TREND_FAILURE";

export const FETCH_BRAND_SCORES_REQUEST = "FETCH_BRAND_SCORES_REQUEST";
export const FETCH_BRAND_SCORES_SUCCESS = "FETCH_BRAND_SCORES_SUCCESS";
export const FETCH_BRAND_SCORES_FAILURE = "FETCH_BRAND_SCORES_FAILURE";
