import { useCallback, useEffect, useState } from "react";
import Modal from "../../../../DesignSystem/components/Modal";
import AdsForm from "../Forms/AdsForm";
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import SiteForm from "../Forms/SiteForm";
import ProfileForm from "../Forms/ProfileForm";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { changeFraudsFormError, cleanForms, linkThreatOccurrences, registerFraud, removeFormLinkOccurrence, setLoading } from "../../../../store/threats/actions";
import ContentForm from "../Forms/ContentForm";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";
import { isUrlValid } from "../../../../DesignSystem/utils/is-url-valid.util";
import Snackbar from "../../../../DesignSystem/components/Snackbar";

interface IProps {
    isOpen: boolean
    onClose: any
    setShowSnackNewThreat: any
}

export default function ModalNewThreats({ isOpen, onClose, setShowSnackNewThreat }: IProps) {
    const dispatch = useDispatch()

    const [typeForm, setTypeForm] = useState<ThreatTypeTextEnum>(ThreatTypeTextEnum.FAKE_AD);
    const [showSnackInvalidUrl, setShowSnackInvalidUrl] = useState(false);
    const [showSnackUrlAlreadyExists, setShowSnackUrlAlreadyExists] = useState(false);

    const { adsForm, profileForm, siteForm, contentForm, selectedCompany, formErrorMessage, formError, formLinkOccurrences } = useSelector((store: IStore) => ({
        adsForm: store.Threats.forms.adsForm,
        profileForm: store.Threats.forms.profileForm,
        siteForm: store.Threats.forms.siteForm,
        contentForm: store.Threats.forms.contentForm,
        threatsList: store.Threats.threatsList,
        selectedCompany: store.Profile.selectedCompany,
        formErrorMessage: store.Threats.forms.formErrorMessage,
        formLinkOccurrences: store.Threats.forms.formLinkOccurrences,
        formError: store.Threats.forms.formError,
    }))


    const [showModalLinkOccurrences, setShowModalLinkOccurrences] = useState(false);

    useEffect(() => {
        setShowModalLinkOccurrences(Boolean(formLinkOccurrences?.link))
    }, [formLinkOccurrences]);

    function closeModalLinkOccurrence() {
        setShowModalLinkOccurrences(false)
        dispatch(removeFormLinkOccurrence())
    }

    const isButtonDisabled = () => {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD: return !Object.entries(adsForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink' && key !== 'id'
                    && key !== 'instagramProfile')
                .every(([, value]) => Boolean(value)) || !(adsForm.evidence!?.length > 0 || Boolean(adsForm.evidenceLink))
            case ThreatTypeTextEnum.FAKE_PROFILE: return !Object.entries(profileForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink')
                .every(([, value]) => Boolean(value)) || !(profileForm.evidence!?.length > 0 || Boolean(profileForm.evidenceLink))
            case ThreatTypeTextEnum.FAKE_SITE: return !(siteForm.searchText && siteForm.occurrences!.every(el => el.url && (el?.evidence?.link || el.evidence?.files?.length > 0)))
            case ThreatTypeTextEnum.FAKE_CONTENT: return !Object.entries(contentForm)
                .filter(([key]) => key !== 'isActive' && key !== 'evidence' && key !== 'evidenceLink')
                .every(([, value]) => Boolean(value)) || !(contentForm.evidence!?.length > 0 || Boolean(contentForm.evidenceLink))
        }
    }

    function changeTypeForm(threatTypeText: ThreatTypeTextEnum) {
        dispatch(cleanForms())
        setTypeForm(threatTypeText)
    }

    const renderForm = useCallback(() => {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD: return <AdsForm isInvalidUrl={showSnackInvalidUrl} setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_PROFILE: return <ProfileForm isInvalidUrl={showSnackInvalidUrl} setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_SITE: return <SiteForm isInvalidUrl={showSnackInvalidUrl} setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_CONTENT: return <ContentForm isInvalidUrl={showSnackInvalidUrl} setTypeForm={changeTypeForm} />
        }
    }, [typeForm, showSnackInvalidUrl])

    async function registerThreat() {
        dispatch(setLoading(true))
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD:
                if (!isUrlValid(adsForm.url) || !isUrlValid(adsForm.evidenceLink!)) {
                    setShowSnackInvalidUrl(true)
                    return dispatch(setLoading(false))
                }

                dispatch(registerFraud({
                    platform: adsForm.platform, type: ThreatTypeEnum.FAKE_AD,
                    isActive: adsForm.isActive, evidence: adsForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: adsForm.evidenceLink,
                    advertiserName: adsForm.advertiserName, advertiserId: adsForm.advertiserId,
                    id: adsForm.id, instagramProfile: adsForm.instagramProfile,
                    searchDate: new Date(adsForm.searchDate), startDate: new Date(adsForm.startDate),
                    adsQuantity: adsForm.adsQuantity
                }))
                break;
            case ThreatTypeTextEnum.FAKE_PROFILE:
                if (!isUrlValid(profileForm.url) || !isUrlValid(profileForm.evidenceLink!)) {
                    setShowSnackInvalidUrl(true)
                    return dispatch(setLoading(false))
                }

                return console.log(profileForm)

                dispatch(registerFraud({
                    platform: profileForm.platform, type: ThreatTypeEnum.FAKE_PROFILE,
                    isActive: profileForm.isActive, evidence: profileForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: profileForm.evidenceLink,
                    profile: profileForm.profile, searchDate: new Date(profileForm.searchDate)
                }))
                break;
            case ThreatTypeTextEnum.FAKE_SITE:
                // if (!isUrlValid(siteForm.url)  || !isUrlValid(siteForm.evidenceLink!)) {
                //     setShowSnackInvalidUrl(true)
                //     return dispatch(setLoading(false))
                // }

                dispatch(registerFraud({ ...siteForm, companyId: selectedCompany?.value!, domain: siteForm.searchText }))

                break;
            case ThreatTypeTextEnum.FAKE_CONTENT:
                if (!isUrlValid(contentForm.url) || !isUrlValid(contentForm.evidenceLink!)) {
                    setShowSnackInvalidUrl(true)
                    return dispatch(setLoading(false))
                }

                return console.log(contentForm)

                dispatch(registerFraud({
                    platform: contentForm.platform, type: ThreatTypeEnum.FAKE_CONTENT,
                    isActive: contentForm.isActive, evidence: contentForm.evidence,
                    companyId: selectedCompany?.value!, evidenceLink: contentForm.evidenceLink,
                    profile: contentForm.profile
                }))
                break;
        }
    }

    function linkOccurrences() {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD:
                dispatch(linkThreatOccurrences({ occurrences: adsForm.occurrences!, threatId: formLinkOccurrences.threatId, type: ThreatTypeEnum.FAKE_AD }))
                break;
            case ThreatTypeTextEnum.FAKE_PROFILE:
                dispatch(linkThreatOccurrences({ occurrences: profileForm.occurrences!, threatId: formLinkOccurrences.threatId, type: ThreatTypeEnum.FAKE_PROFILE }))
                break;
            case ThreatTypeTextEnum.FAKE_SITE:
                dispatch(linkThreatOccurrences({ occurrences: siteForm.occurrences!, threatId: formLinkOccurrences.threatId, type: ThreatTypeEnum.FAKE_SITE }))
                break;
            case ThreatTypeTextEnum.FAKE_CONTENT:
                dispatch(linkThreatOccurrences({ occurrences: contentForm.occurrences!, threatId: formLinkOccurrences.threatId, type: ThreatTypeEnum.FAKE_CONTENT }))
                break;
        }
        closeModalLinkOccurrence()
        alert('Vinculado com sucesso!')
    }

    return (
        <>
            <Modal
                width={728}
                title="Incluir ameaça"
                subtitle="Preencha abaixo as informações solicitadas."
                isOpen={isOpen}
                onClose={onClose}
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: registerThreat, label: 'Inserir', state: isButtonDisabled() ? 'disabled' : 'default' },
                ]}
            >
                {renderForm()}
            </Modal>
            <Snackbar setVisible={setShowSnackInvalidUrl} visible={showSnackInvalidUrl} state="error">
                Insira uma URL válida, por exemplo: https://www.url.com.br/
            </Snackbar>

            <Snackbar setVisible={setShowSnackUrlAlreadyExists} visible={showSnackUrlAlreadyExists} state="error">
                {formErrorMessage}.
            </Snackbar>

            <Modal
                width={300}
                title="Vincular ocorrências"
                subtitle="Deseja vincular as ocorrências à ameaça existente?"
                isOpen={showModalLinkOccurrences}
                onClose={closeModalLinkOccurrence}
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: closeModalLinkOccurrence, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: linkOccurrences, label: 'Vincular', state: isButtonDisabled() ? 'disabled' : 'default' },
                ]}>
                <p>A ameaça em questão já existe. Deseja vincular as ocorrências preenchidas à ameaça existente?</p>
            </Modal>

        </>

    );
}
