import { useEffect, useState } from "react"
import { styled } from "styled-components"
import { dsVariables } from "../variables"
import { useLocation } from "react-router-dom"
import Caption from "./Text/Caption"
import { SvgIconComponent } from "@mui/icons-material"
import Icon from "./Icon"
import BranddiIcon from "../assets/icons/brand-icon.svg"
import Label from "./Text/Label"
import { useSelector } from "react-redux"
import { IStore } from "../../interfaces/store.interface"
export interface ITabs {
    label?: string
    iconLeft?: SvgIconComponent | 'branddi'
    iconRight?: SvgIconComponent | 'badge'
    iconRightLabel?: string | number
    mode?: 'light' | 'dark'
    onClick: any
    tooltip?: string | number
}

interface IProps {
    tabs: ITabs[]
    indexActive?: number
}

export default function Tabs({ tabs, indexActive }: IProps) {
    const [activeTab, setActiveTab] = useState(indexActive);

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const { showAllCompanies } = useSelector((store: IStore) => store.Profile.user)

    useEffect(() => {
        const tabIndex = Number(searchParams.get('tab'))
        if (tabIndex != activeTab) setActiveTab(tabIndex)
    }, [location.search]);

    function handleClick(onClick: any, index: number) {
        onClick()
        setActiveTab(index)
    }

    return (
        <Div className="d-flex">
            {tabs.map((tab, index) =>
                <TabButton isCustomer={!showAllCompanies} tabIndex={index} onClick={_ => handleClick(tab.onClick, index)} key={index} className="bg-transparent border-0 d-flex gap-2 align-items-center" isActive={activeTab == index} >
                    {tab.iconLeft ? tab.iconLeft != 'branddi'
                        ? <Icon IconComponent={tab.iconLeft} />
                        : <img src={BranddiIcon} width={24} height={24} alt="Branddi Icon" />
                        : null
                    }
                    {tab.label && <Label>{tab.label}</Label>}
                    {
                        tab.iconRight ? tab.iconRight != "badge"
                            ? <Icon IconComponent={tab.iconRight} />
                            : <Badge activeTab={activeTab} showAllCompanies={showAllCompanies} tabIndex={index} className="rounded-pill position-relative d-flex align-items-center justify-content-center">
                                <div className="position-absolute">
                                    <Caption color={activeTab == index ? dsVariables.colors.Astronaut[0] : dsVariables.colors.Gray[700]}>{tab.iconRightLabel}</Caption>
                                </div>
                            </Badge>
                            : null
                    }
                </TabButton>
            )}
        </Div>
    )
}

const Div = styled.div`
    min-height:40px;

    overflow-x:scroll;
    overflow-y:hidden;

    label{
        cursor: pointer;
    }

    &::-webkit-scrollbar {
        display:none;
    }
`

const TabButton = styled.button<{ isActive: boolean, isCustomer: boolean }>`
    height: ${dsVariables.spacing[40]};
    padding: ${dsVariables.spacing[8]} ${dsVariables.spacing[16]};
    gap: ${dsVariables.spacing[4]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px 0px 0px 0px;
    font-family: Inter;

    :first-child{
        overflow-y: hidden;
    }

    &:hover{
        background-color: ${dsVariables.colors.Gray[100]} !important;
    }

    ${({ isActive }) =>
        isActive ?
            `
            color: ${dsVariables.colors.Astronaut[900]};
            line-height: 120%;
            letter-spacing: 0.336px;
            position: relative;

            &:after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: ${dsVariables.colors.Astronaut[900]};
                bottom: 0;
                left: 0;
                position: absolute;
            }
        `
            :
            `
            color: ${dsVariables.colors.Gray[600]};
        `
    }

    ${props => ((props.tabIndex == 0 && !props.isCustomer) || (props.tabIndex == 2 && props.isCustomer)) &&
        `
        &::before {
            content: '';
            position:absolute;
            display: inline-block;
            height:10px;
            width:100%;
            left:0;
            bottom:-10px;
            font-weight: bold;
            background-color: #e0e7ff; /* Light blue background */
            box-shadow: 0px 0px 32px ${dsVariables.colors.Blue[100]};
            animation: shadowBox 6s infinite;
        }

        @keyframes shadowBox {
            0% {
                box-shadow: -1px -1px 32px ${dsVariables.colors.Blue[100]};
            }
            50% {
                box-shadow: -1px -1px 32px ${dsVariables.colors.Blue[400]};
            }
            100% {
                box-shadow: -1px -1px 32px ${dsVariables.colors.Blue[100]};
            }
        }
        `
    }
`

const Badge = styled.div<{ tabIndex?: number, showAllCompanies?: boolean, activeTab?: number }>`
    background-color: ${props => props.tabIndex == props.activeTab ? dsVariables.colors.Astronaut[500] : dsVariables.colors.Gray[200]};
    padding: ${dsVariables.spacing[12]};
    padding-inline: ${dsVariables.spacing[16]};
`