import { Container, Row } from "reactstrap";
import styled from "styled-components";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import SectionTitle from "../../../DesignSystem/components/SectionTitle/SectionTitle";
import ButtonSecondary from "../../../DesignSystem/components/Button/ButtonSecondary";
import FilterSection from "../../../DesignSystem/components/FilterSection/FilterSection";
import { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss"
import Input from "../../../DesignSystem/components/Input/Input";
import Tabs, { ITabs } from "../../../DesignSystem/components/Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { Tune, Search, WebAssetOff, Warning, AddRounded, ExpandMore, Close } from "@mui/icons-material";
import { dsVariables } from "../../../DesignSystem/variables";
import Modal, { IFooterButton } from "../../../DesignSystem/components/Modal";
import { ThreatTypeEnum } from "../../../enums/threat-type.enum";
import { ThreatStateEnum } from "../../../enums/threat-state.enum";
import { ThreatOptionsCardEnum } from "../../../enums/threat-options-card.enum";
import ModalTakedown from "./Modals/ModalTakedown";
import ModalQuarantine from "./Modals/ModalQuarantine";
import ModalDiscard from "./Modals/ModalDiscard";
import TableThreats from "../../../DesignSystem/components/Table/TableThreats/Table";
import { IThreatsFilter } from "../../../interfaces/threats-filter.interface";
import { ThreatPlatformEnum } from "../../../enums/threat-platform.enum";
import ModalNewThreats from "./Modals/ModalNewThreats";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../interfaces/store.interface";
import { changeFilterPlatform, changeFilterStatus, changeFilterText, changeFilterType, changeFraudsFilteredQuantityByState, changeFraudsQuantityByState, changeThreatsCurrent, changeThreatsCurrentIds, changeThreatsList, changeThreatsTable, getFraudsByCompanyId, getThreatDetails, registerFraud, restoreFraud, restoreMultipleFrauds, setLoading, updateFraud, updateMultipleFrauds } from "../../../store/threats/actions";
import { ThreatDaysEnum } from "../../../enums/threat-days.enum";
import Snackbar from "../../../DesignSystem/components/Snackbar";
import { IThreats } from "../../../interfaces/threats.interface";
import ModalResolved from "./Modals/ModalResolved";
import ModalApproval from "./Modals/ModalApproval";
import { ThreatStatusActiveEnum } from "../../../enums/threat-status-active.enum";
import Label from "../../../DesignSystem/components/Text/Label";
import ModalCancelApproval from "./Modals/ModalCancelApproval";
import ButtonPrimary from "../../../DesignSystem/components/Button/ButtonPrimary";
import OptionsCard from "../../../DesignSystem/components/Table/TableThreats/OptionsCard";
import Loader from "../../../DesignSystem/components/Loader";
import ModalRestore from "./Modals/ModalRestore";
import { getCompanyProductsByCompanyId } from "../../../store/companyProducts/action";
import Chip from "../../../DesignSystem/components/Chip";
import { getThreatTypeText } from "../../../DesignSystem/utils/get-threat-type-text.util";
import ModalEdit from "./Modals/ModalEdit";
import Body from "../../../DesignSystem/components/Text/Body";
import useCanPerformAction from "../../../DesignSystem/hooks/CanPerformAction.hook";
import { ActionEnum } from "../../../DesignSystem/constants/user-action-permissions.constant";
import Accordion from "../../components/Accordion";
import Icon from "../../components/Icon";
import ModalDetailsThreat from "./Modals/ModalDetailsThreat";

export default function ManageThreats() {

    document.title = "Branddi.com - Gerenciar Ameaças";

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const tabActive: number = Number(searchParams.get('tab'));
    const tablePage: number = Number(searchParams.get('page'));

    const filterButtonRef = useRef<HTMLDivElement | null>(null);
    const filterRef = useRef<HTMLDivElement | null>(null);
    const buttonActionsRef = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();

    const { threatsTableData, filter, currentIds, current,
        threatsList, selectedCompany, isCustomer,
        isLoading, companyProducts, fraudsQuantityByState,
        fraudsFilteredQuantityByState, forms, user
    } = useSelector((store: IStore) => ({
        threatsTableData: store.Threats.threatsTableData,
        filter: store.Threats.filter,
        currentIds: store.Threats.currentIds,
        current: store.Threats.current,
        threatsList: store.Threats.threatsList,
        selectedCompany: store.Profile.selectedCompany,
        isCustomer: store.Profile.user.isCustomer,
        isLoading: store.Threats.isLoading,
        companyProducts: store.CompanyProducts.products,
        fraudsQuantityByState: store.Threats.fraudsQuantityByState,
        fraudsFilteredQuantityByState: store.Threats.fraudsFilteredQuantityByState,
        forms: store.Threats.forms,
        user: store.Profile.user
    }))

    const [openFilter, setOpenFilter] = useState(false);

    const [showModalDetails, setShowModalDetails] = useState<boolean>(false);
    const [showModalTakedown, setShowModalTakedown] = useState<boolean>(false);
    const [showModalDiscard, setShowModalDiscard] = useState<boolean>(false);
    const [showModalQuarantine, setShowModalQuarantine] = useState<boolean>(false);
    const [showModalResolved, setShowModalResolved] = useState<boolean>(false);
    const [showModalApproval, setShowModalApproval] = useState<boolean>(false);
    const [showModalNewThreats, setShowModalNewThreats] = useState<boolean>(false);
    const [showModalCancelApproval, setShowModalCancelApproval] = useState<boolean>(false);
    const [showModalRestore, setShowModalRestore] = useState<boolean>(false);
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
    const [showModalBBCta, setShowModalBBCta] = useState(false);

    const [showSnackQuarantine, setShowSnackQuarantine] = useState(false);
    const [showSnackDiscarded, setShowSnackDiscarded] = useState(false);
    const [showSnackTakedown, setShowSnackTakedown] = useState(false);
    const [showSnackNewThreat, setShowSnackNewThreat] = useState(false);
    const [showSnackResolved, setShowSnackResolved] = useState(false);
    const [showSnackApproval, setShowSnackApproval] = useState(false);
    const [showSnackAutomaticApproval, setShowSnackAutomaticApproval] = useState(false);
    const [showSnackCancelApproval, setShowSnackCancelApproval] = useState(false);
    const [showSnackFraudStatus, setShowSnackFraudStatus] = useState(false);
    const [showSnackRestore, setShowSnackRestore] = useState(false);
    const [showSnackEdit, setShowSnackEdit] = useState(false);

    const [showOptionsCard, setShowOptionsCard] = useState(false);

    const [threatsSelected, setThreatsSelected] = useState(0);
    const [threatsSelectedAutomaticApproval, setThreatsSelectedAutomaticApproval] = useState(0);

    const showAddThreat = useCanPerformAction(ActionEnum.manually_add_threat)

    const tabs: ITabs[] = [
        { label: "Novas", onClick: () => tabClick(0), iconRight: "badge", iconRightLabel: fraudsFilteredQuantityByState.new > 100 ? '100+' : fraudsFilteredQuantityByState.new, tooltip: fraudsFilteredQuantityByState.new },
        { label: "Quarentena", onClick: () => tabClick(1), iconRight: "badge", iconRightLabel: fraudsFilteredQuantityByState.quarantine > 100 ? '100+' : fraudsFilteredQuantityByState.quarantine, tooltip: fraudsFilteredQuantityByState.quarantine },
        { label: "Aguardando aprovação", onClick: () => tabClick(2), iconRight: "badge", iconRightLabel: fraudsFilteredQuantityByState.awaiting_approval > 100 ? '100+' : fraudsFilteredQuantityByState.awaiting_approval, tooltip: fraudsFilteredQuantityByState.awaiting_approval },
        { label: "Descartadas", onClick: () => tabClick(3), iconRight: "badge", iconRightLabel: fraudsFilteredQuantityByState.discarded > 100 ? '100+' : fraudsFilteredQuantityByState.discarded, tooltip: fraudsFilteredQuantityByState.discarded },
        { label: "Em tratativa", onClick: () => tabClick(4), iconRight: "badge", iconRightLabel: fraudsFilteredQuantityByState.under_discussion > 100 ? '100+' : fraudsFilteredQuantityByState.under_discussion, tooltip: fraudsFilteredQuantityByState.under_discussion },
        { label: "Resolvidas", onClick: () => tabClick(5), iconRight: "badge", iconRightLabel: fraudsFilteredQuantityByState.resolved > 100 ? '100+' : fraudsFilteredQuantityByState.resolved, tooltip: fraudsFilteredQuantityByState.resolved },
    ]

    function tabClick(index: number) {
        searchParams.set('tab', index.toString());
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    function showDetails(id: string) {
        dispatch(changeThreatsCurrent(threatsList?.find(el => el._id == id)!))
        dispatch(getThreatDetails(threatsList.find(el => el._id == id)?._id!))
        setShowModalDetails(true)
    }

    function showOptions(id: string, option: ThreatOptionsCardEnum) {
        dispatch(changeThreatsCurrent(threatsList?.find(el => el._id == id)!))

        switch (option) {
            case ThreatOptionsCardEnum.TAKEDOWN: return setShowModalTakedown(true)
            case ThreatOptionsCardEnum.DISCARD: return setShowModalDiscard(true)
            case ThreatOptionsCardEnum.QUARANTINE: return setShowModalQuarantine(true)
            case ThreatOptionsCardEnum.RESOLVED: return setShowModalResolved(true)
            case ThreatOptionsCardEnum.APPROVAL:
                var currentType = threatsList.find(el => el._id == id)?.type

                var isAutomaticApproval = companyProducts
                    ?.find(el => el.productName == 'fraud')?.automaticApprovals
                    ?.find(el => el.product == currentType)?.isAutomatic

                if (isAutomaticApproval) return setShowModalTakedown(true)
                return setShowModalApproval(true)
            case ThreatOptionsCardEnum.NEW: return setShowModalCancelApproval(true)
            case ThreatOptionsCardEnum.RESTORE: return setShowModalRestore(true)
            case ThreatOptionsCardEnum.EDIT: return setShowModalEdit(true)
        }
    }

    function handleOptionsCardClick(option: ThreatOptionsCardEnum) {
        switch (option) {
            case ThreatOptionsCardEnum.TAKEDOWN: return setShowModalTakedown(true)
            case ThreatOptionsCardEnum.DISCARD: return setShowModalDiscard(true)
            case ThreatOptionsCardEnum.QUARANTINE: return setShowModalQuarantine(true)
            case ThreatOptionsCardEnum.RESOLVED: return setShowModalResolved(true)
            case ThreatOptionsCardEnum.APPROVAL: return setShowModalApproval(true)
            case ThreatOptionsCardEnum.NEW: return setShowModalCancelApproval(true)
            case ThreatOptionsCardEnum.RESTORE: return setShowModalRestore(true)
        }
    }

    function takedownThreat() {
        dispatch(setLoading(true))
        if (currentIds.length > 0) {
            const updatedThreats: IThreats[] = currentIds.map(currentId => {
                const threat = threatsList.find(el => el._id == currentId)
                return { ...threat, currentState: ThreatStateEnum.UNDER_DISCUSSION } as IThreats
            })

            dispatch(updateMultipleFrauds(updatedThreats))
        } else dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.UNDER_DISCUSSION }))

        closeModalsAndSnacks()
        setThreatsSelected(currentIds.length)
        // tabClick(4)
        setShowSnackTakedown(true)
    }

    function approvalThreat() {
        dispatch(setLoading(true))
        if (currentIds.length > 0) {
            const updatedThreats: IThreats[] = currentIds.map(currentId => {
                const threat = threatsList.find(el => el._id == currentId)

                const isAutomaticApproval = companyProducts
                    ?.find(el => el.productName == 'fraud')?.automaticApprovals
                    ?.find(el => el.product == threat?.type)?.isAutomatic

                return { ...threat, currentState: isAutomaticApproval ? ThreatStateEnum.UNDER_DISCUSSION : ThreatStateEnum.AWAITING_APPROVAL } as IThreats
            })
            dispatch(updateMultipleFrauds(updatedThreats))

            closeModalsAndSnacks()

            if (updatedThreats.find(el => el.currentState == ThreatStateEnum.UNDER_DISCUSSION)) {
                setThreatsSelectedAutomaticApproval(updatedThreats.filter(el => el.currentState == ThreatStateEnum.UNDER_DISCUSSION).length)
                setShowSnackAutomaticApproval(true)
            }

            if (updatedThreats.find(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL)) {
                setThreatsSelected(updatedThreats.filter(el => el.currentState == ThreatStateEnum.AWAITING_APPROVAL).length)
                setShowSnackApproval(true)
            }
        } else {
            dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.AWAITING_APPROVAL }))

            closeModalsAndSnacks()
            setThreatsSelected(currentIds.length)
            setShowSnackApproval(true)
        }
    }

    function restoreLast() {
        dispatch(setLoading(true))

        if (currentIds.length > 0) {
            const updatedThreats: IThreats[] = currentIds.map(currentId => {
                const threat = threatsList?.find(el => el._id == currentId)
                return threat as IThreats
            })

            dispatch(restoreMultipleFrauds(updatedThreats))
        } else {
            const threat = threatsList?.find(el => el._id == current?._id) as IThreats

            dispatch(restoreFraud(threat))
        }

        closeModalsAndSnacks()
        setThreatsSelected(currentIds.length)
        setShowSnackRestore(true)
    }

    function cancelThreatApproval() {
        dispatch(setLoading(true))
        if (currentIds.length > 0) {
            const updatedThreats: IThreats[] = currentIds.map(currentId => {
                const threat = threatsList.find(el => el._id == currentId)
                return { ...threat, currentState: ThreatStateEnum.NEW } as IThreats
            })

            dispatch(updateMultipleFrauds(updatedThreats))
        } else dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.NEW }))

        closeModalsAndSnacks()
        setThreatsSelected(currentIds.length)
        // tabClick(0)
        setShowSnackCancelApproval(true)
    }

    function discardThreat() {
        dispatch(setLoading(true))
        if (currentIds.length > 0) {
            const updatedThreats: IThreats[] = currentIds.map(currentId => {
                const threat = threatsList.find(el => el._id == currentId)
                return { ...threat, currentState: ThreatStateEnum.DISCARDED } as IThreats
            })

            dispatch(updateMultipleFrauds(updatedThreats))
        } else dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.DISCARDED }))

        closeModalsAndSnacks()
        setThreatsSelected(currentIds.length)
        // tabClick(3)
        setShowSnackDiscarded(true)
    }

    function quarantineThreat() {
        dispatch(setLoading(true))
        if (currentIds.length > 0) {
            const updatedThreats: IThreats[] = currentIds.map(currentId => {
                const threat = threatsList.find(el => el._id == currentId)
                return { ...threat, currentState: ThreatStateEnum.QUARANTINE } as IThreats
            })

            dispatch(updateMultipleFrauds(updatedThreats))
        } else dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.QUARANTINE }))

        closeModalsAndSnacks()
        setThreatsSelected(currentIds.length)
        // tabClick(1)
        setShowSnackQuarantine(true)
    }

    function resolvedThreat() {
        dispatch(setLoading(true))

        if (currentIds.length > 0) {
            const updatedThreats: IThreats[] = currentIds.map(currentId => {
                const threat = threatsList.find(el => el._id == currentId)
                return { ...threat, currentState: ThreatStateEnum.RESOLVED } as IThreats
            })

            dispatch(updateMultipleFrauds(updatedThreats))
        } else dispatch(updateFraud({ ...current!, currentState: ThreatStateEnum.RESOLVED }))

        closeModalsAndSnacks()
        setThreatsSelected(currentIds.length)
        // tabClick(5)
        setShowSnackResolved(true)
    }

    function editThreat() {
        dispatch(setLoading(true))

        switch (current?.type) {
            case ThreatTypeEnum.FAKE_SITE:
                dispatch(updateFraud({
                    ...current,
                    isActive: forms.siteForm.isActive
                }))
                break;
            case ThreatTypeEnum.FAKE_AD:
                dispatch(updateFraud({
                    ...current, evidenceLink: forms.adsForm.evidenceLink,
                    evidence: forms.adsForm.evidence, isActive: forms.adsForm.isActive,
                    adsQuantity: forms.adsForm.adsQuantity
                }))
                break;
            case ThreatTypeEnum.FAKE_PROFILE:
                dispatch(updateFraud({
                    ...current, evidenceLink: forms.profileForm.evidenceLink,
                    evidence: forms.profileForm.evidence, isActive: forms.profileForm.isActive
                }))
                break;
            case ThreatTypeEnum.FAKE_CONTENT:
                dispatch(updateFraud({
                    ...current, evidenceLink: forms.contentForm.evidenceLink,
                    evidence: forms.contentForm.evidence, isActive: forms.contentForm.isActive
                }))
                break;
        }

        closeModalsAndSnacks()
        // tabClick(4)
        setShowSnackEdit(true)
    }

    function closeModalsAndSnacks() {
        setShowModalApproval(false)
        setShowModalDiscard(false)
        setShowModalQuarantine(false)
        setShowModalTakedown(false)
        setShowModalResolved(false)
        setShowModalDetails(false)
        setShowModalCancelApproval(false)
        setShowOptionsCard(false)
        setShowModalRestore(false)
        setShowSnackQuarantine(false)
        setShowSnackDiscarded(false)
        setShowSnackTakedown(false)
        setShowSnackNewThreat(false)
        setShowSnackResolved(false)
        setShowSnackApproval(false)
        setShowSnackCancelApproval(false)
        setShowSnackFraudStatus(false)
        setShowSnackRestore(false)
        setShowSnackEdit(false)
        setShowModalEdit(false)
        dispatch(changeThreatsCurrentIds([]))
        setTimeout(() => {
            dispatch(getFraudsByCompanyId({
                companyId: selectedCompany!.value, itemsPerPage: Number(searchParams.get('itemsPerPage')) || 10,
                page: Number(searchParams.get('page')) || 1, type: searchParams.get('type'), platform: searchParams.get('platform'),
                status: searchParams.get('status'), startDate: searchParams.get('startDate'), endDate: searchParams.get('endDate'),
                state: getStateByTabActive()
            }))
        }, 500);
    }

    function filterTableData(currentState: ThreatStateEnum, filter: IThreatsFilter) {
        const calculateDaysAgoDate = (days: number): Date => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            today.setDate(today.getDate() - days);
            return today;
        };

        const isWithinLastDays = (date: Date, daysEnum: ThreatDaysEnum): boolean => {
            switch (daysEnum) {
                case ThreatDaysEnum.DAYS_7: return date >= calculateDaysAgoDate(7);
                case ThreatDaysEnum.DAYS_14: return date >= calculateDaysAgoDate(14);
                case ThreatDaysEnum.DAYS_28: return date >= calculateDaysAgoDate(28);
                case ThreatDaysEnum.ALL_DAYS:
                default:
                    return true;
            }
        };

        return threatsList!.filter((threat) => {
            const matchesState = threat.currentState === currentState;
            const matchesText = filter.text
                ? threat.sourceThreat.searchText.toLowerCase().includes(filter.text.toLowerCase())
                : true;

            const matchesPlatform = filter.platform !== ThreatPlatformEnum.ALL
                ? threat.sourceThreat.platform === filter.platform
                : true;
            const matchesType = filter.type !== ThreatTypeEnum.ALL
                ? threat.type === filter.type
                : true;

            const matchesDateRangeStartDate = filter.dateRange?.startDate
                ? new Date(threat.createdAt) >= filter.dateRange.startDate
                : true

            const matchesDateRangeEndDate = filter.dateRange?.endDate
                ? new Date(threat.createdAt) <= new Date(new Date(filter.dateRange.endDate).setDate(new Date(filter.dateRange.endDate).getDate() + 1))
                : true

            const matchesDays = (filter.days && filter.days !== ThreatDaysEnum.ALL_DAYS)
                ? isWithinLastDays(new Date(threat.createdAt), filter.days)
                : true;

            const matchesStatus = searchParams.get('status') != 'Todos'
                ? filter.status == threat.isActive
                : true

            return matchesState && matchesText && matchesPlatform && matchesType && matchesDateRangeStartDate && matchesDateRangeEndDate && matchesDays && matchesStatus;
        }).map(el => ({ ...el, checked: false }));
    }

    useEffect(() => {
        dispatch(changeThreatsCurrentIds([]))
    }, [tabActive]);

    function changeTabsQuantityByFilter() {
        dispatch(changeFraudsFilteredQuantityByState({
            new: fraudsQuantityByState.new,
            quarantine: fraudsQuantityByState.quarantine,
            awaiting_approval: fraudsQuantityByState.awaiting_approval,
            discarded: fraudsQuantityByState.discarded,
            under_discussion: fraudsQuantityByState.under_discussion,
            resolved: fraudsQuantityByState.resolved,
        }))
    }

    const changeTable = useCallback(() => {
        if (!threatsList) return

        changeTabsQuantityByFilter()

        switch (tabActive) {
            case 0: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.NEW, filter)))
            case 1: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.QUARANTINE, filter)))
            case 2: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.AWAITING_APPROVAL, filter)))
            case 3: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.DISCARDED, filter)))
            case 4: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.UNDER_DISCUSSION, filter)))
            case 5: return dispatch(changeThreatsTable(filterTableData(ThreatStateEnum.RESOLVED, filter)))
            default: return dispatch(changeThreatsTable(threatsTableData!))
        }
    }, [tabActive, filter, threatsList, tablePage])

    useEffect(() => {
        changeTable()
    }, [changeTable]);

    useEffect(() => {
        if (selectedCompany?.value) {
            dispatch(setLoading(true))
            dispatch(getFraudsByCompanyId({
                companyId: selectedCompany!.value, itemsPerPage: Number(searchParams.get('itemsPerPage')) || 10,
                page: Number(searchParams.get('page')) || 1, type: searchParams.get('type'), platform: searchParams.get('platform'),
                status: searchParams.get('status'), startDate: searchParams.get('startDate'), endDate: searchParams.get('endDate'),
                state: getStateByTabActive(), text: searchParams.get('text')
            }))
            dispatch(getCompanyProductsByCompanyId(selectedCompany.value))
        }
    }, [selectedCompany, location]);

    function getStateByTabActive() {
        switch (tabActive) {
            case 0: return ThreatStateEnum.NEW
            case 1: return ThreatStateEnum.QUARANTINE
            case 2: return ThreatStateEnum.AWAITING_APPROVAL
            case 3: return ThreatStateEnum.DISCARDED
            case 4: return ThreatStateEnum.UNDER_DISCUSSION
            case 5: return ThreatStateEnum.RESOLVED
            default: return ThreatStateEnum.NEW
        }
    }

    useEffect(() => {
        if (!searchParams.get('type')) searchParams.set('type', filter.type);
        else dispatch(changeFilterType(searchParams.get('type') as ThreatTypeEnum))

        if (!searchParams.get('platform')) searchParams.set('platform', filter.platform);
        else dispatch(changeFilterPlatform(searchParams.get('platform') as ThreatPlatformEnum))

        if (!searchParams.get('status')) searchParams.set('status', 'Todos');
        else dispatch(changeFilterStatus(searchParams.get('status') == ThreatStatusActiveEnum.ACTIVE))

        if (searchParams.get('text')) dispatch(changeFilterText(searchParams.get('text')!))

        if (location.search.includes('showModalBBCta')) setShowModalBBCta(true)
        else setShowModalBBCta(false)

        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, [location]);

    useEffect(() => {
        if (isCustomer) {
            searchParams.set('tab', '2')
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }
    }, []);

    useEffect(() => {
        function closeFilter(event: MouseEvent) {
            if (filterRef.current && !filterRef.current.contains(event.target as Node) &&
                filterButtonRef.current && !filterButtonRef.current.contains(event.target as Node)
            ) {
                setOpenFilter(false);
            }
        }

        document.addEventListener('mousedown', closeFilter)

        return () => {
            document.removeEventListener('mousedown', closeFilter)
        }
    }, [openFilter]);

    useEffect(() => {
        function closeFilter(event: MouseEvent) {
            if (buttonActionsRef.current && !buttonActionsRef.current.contains(event.target as Node)) {
                setShowOptionsCard(false);
            }
        }

        document.addEventListener('mousedown', closeFilter)

        return () => {
            document.removeEventListener('mousedown', closeFilter)
        }
    }, [showOptionsCard]);


    function getQuantityOfFiltersActive() {
        const type = searchParams.get('type') != 'all' ? 1 : 0
        const platform = searchParams.get('platform') != 'Todos' ? 1 : 0
        const status = searchParams.get('status') != 'Todos' ? 1 : 0

        return type + platform + status
    }

    function getFilterChips() {
        const type = searchParams.get('type') != ThreatTypeEnum.ALL ? <Chip onClick={() => {
            searchParams.set('type', ThreatTypeEnum.ALL)
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }}
            label={getThreatTypeText(filter.type)} /> : null
        const platform = searchParams.get('platform') != ThreatPlatformEnum.ALL ? <Chip onClick={() => {
            searchParams.set('platform', ThreatPlatformEnum.ALL)
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }}
            label={filter.platform} /> : null
        const status = searchParams.get('status') != 'Todos' ? <Chip onClick={() => {
            searchParams.set('status', 'Todos')
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }}
            label={filter.status ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE} /> : null

        return getQuantityOfFiltersActive() > 0 && <>
            {type}
            {platform}
            {status}
            <Chip type="clean_all" onClick={() => {
                searchParams.set('type', ThreatTypeEnum.ALL)
                searchParams.set('platform', ThreatPlatformEnum.ALL)
                searchParams.set('status', 'Todos')
                const newSearch = searchParams.toString();

                if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
            }} label="Limpar tudo" />
        </>
    }

    function handleTextFilterInput(e: any) {
        dispatch(changeFilterText(e.target.value))
        setTimeout(() => {
            searchParams.set('text', e.target.value)
            searchParams.set('page', '1');

            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }, 1000);
    }

    function clearTextFilter() {
        dispatch(changeFilterText(''))

        searchParams.delete('text')
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    function canViewButtonToInclude() {
        return !isCustomer && showAddThreat
    }

    const getFooterButtons = (): IFooterButton[] => {
        switch (tabActive) {
            case 0:
                return [
                    { buttonStyle: 'secondary', onClick: () => setShowModalDiscard(true), label: 'Descartar', iconLeft: DeleteOutlinedIcon, iconLeftColor: dsVariables.colors.Red[400], type: 'destructiveBorderless' },
                    { buttonStyle: 'secondary', onClick: () => setShowModalEdit(true), label: 'Editar', iconLeft: ModeEditOutlinedIcon, iconLeftColor: dsVariables.colors.Astronaut[900] },
                    { buttonStyle: 'secondary', onClick: () => setShowModalQuarantine(true), label: 'Quarentena', iconLeft: WarningAmberOutlinedIcon, iconLeftColor: dsVariables.colors.Astronaut[900] },
                    { buttonStyle: 'tertiary', onClick: () => setShowModalApproval(true), label: 'Solicitar Aprovaçao', iconLeft: WebAssetOff, iconLeftColor: 'white' },
                ];
            case 1:
                return [
                    { buttonStyle: 'secondary', onClick: () => setShowModalDiscard(true), label: 'Descartar', iconLeft: DeleteOutlinedIcon, iconLeftColor: dsVariables.colors.Red[400], type: 'destructiveBorderless' },
                    { buttonStyle: 'secondary', onClick: () => setShowModalEdit(true), label: 'Editar', iconLeft: ModeEditOutlinedIcon, iconLeftColor: dsVariables.colors.Astronaut[900] },
                    { buttonStyle: 'tertiary', onClick: () => setShowModalApproval(true), label: 'Solicitar Aprovaçao', iconLeft: WebAssetOff, iconLeftColor: 'white' },
                ];
            case 2:
                return [
                    { buttonStyle: 'secondary', onClick: () => setShowModalDiscard(true), label: 'Descartar', iconLeft: DeleteOutlinedIcon, iconLeftColor: dsVariables.colors.Red[400], type: 'destructiveBorderless' },
                    { buttonStyle: 'secondary', onClick: () => setShowModalCancelApproval(true), label: 'Cancelar solicitação de aprovação', iconLeft: CancelRoundedIcon, iconLeftColor: dsVariables.colors.Astronaut[900] },
                    { buttonStyle: 'tertiary', onClick: () => setShowModalTakedown(true), label: 'Aprovar Takedown', iconLeft: WebAssetOff, iconLeftColor: 'white' },
                ];
            case 3:
                return [
                    { buttonStyle: 'secondary', onClick: () => setShowModalRestore(true), label: 'Restaurar', iconLeft: HistoryOutlinedIcon, iconLeftColor: dsVariables.colors.Astronaut[900] },
                ];
            default:

                return [];
        }
    };

    return (
        <div className="page-content">
            {isLoading && <Loader />}
            <Container fluid>

                <Row className="d-flex flex-column gap-4">
                    <SectionTitle title="Gerenciar Ameaças" />

                    {getQuantityOfFiltersActive() > 0 && <div className="d-flex flex-column gap-1 w-100 align-items-end">
                        <span style={{ fontSize: 10, color: dsVariables.colors.Gray[900] }}>Filtros aplicados</span>

                        <div className="d-flex gap-2">
                            {getFilterChips()}
                        </div>
                    </div>}

                    <div className="d-flex flex-column box-content">
                        <div className="d-flex flex-wrap row-gap-4 justify-content-between align-items-end px-3">
                            {currentIds.length > 0 ?
                                <div ref={buttonActionsRef} className="position-relative">
                                    <ButtonPrimary width={370} iconRight={ExpandMore} iconRightColor="white" label={`Ações em massa (${Number(currentIds.length - 1 < 0 ? 0 : currentIds.length)})`} onClick={() => setShowOptionsCard(!showOptionsCard)} />
                                    {showOptionsCard && <OptionsCard onClick={handleOptionsCardClick} operatorView />}
                                </div>
                                : <div className="py-3"></div>
                            }
                            <div className="d-flex gap-3 flex-wrap">
                                <Input onClickIconRight={clearTextFilter} iconRight={filter.text ? Close : undefined} width={276} value={filter.text.trim()} onChange={handleTextFilterInput} iconLeft={Search} placeholder="Busque pela ameaça" />
                                <div className="position-relative">
                                    <div ref={filterButtonRef}>
                                        <ButtonSecondary onClick={() => setOpenFilter(!openFilter)} type="normal" iconLeft={Tune} label="Filtros" iconLeftColor={dsVariables.colors.Astronaut[900]} />
                                    </div>
                                    {getQuantityOfFiltersActive() > 0 && <div className="position-absolute d-flex align-items-center justify-content-center" style={{ backgroundColor: dsVariables.colors.Red[400], borderRadius: '50%', width: 16, height: 16, top: -6, right: -6 }}>
                                        <span style={{ fontSize: 10, color: 'white' }}>{getQuantityOfFiltersActive()}</span>
                                    </div>}
                                    {openFilter &&
                                        <div ref={filterRef}>
                                            <FilterSection />
                                        </div>
                                    }
                                </div>
                                {canViewButtonToInclude() && <ButtonSecondary onClick={() => setShowModalNewThreats(!showModalNewThreats)} type="normal" iconLeft={AddRounded} label="Incluir ameaça" iconLeftColor={dsVariables.colors.Astronaut[900]} />}
                            </div>
                        </div>

                        <div className="d-flex flex-column h-100">
                            <Tabs tabs={tabs} indexActive={tabActive} />

                            <TableThreats showDetails={showDetails} showOptions={showOptions} />
                        </div>
                    </div>
                </Row>
            </Container>

            <ModalDetailsThreat
                tabActive={tabActive}
                isOpen={showModalDetails}
                onClose={() => setShowModalDetails(false)}
                ModalDiscard={() => setShowModalDiscard(true)}
                ModalEdit={() => setShowModalEdit(true)}
                ModalQuarentine={() => setShowModalQuarantine(true)}
                ModalApproval={() => setShowModalApproval(true)}
                ModalCancelApproval={() => setShowModalCancelApproval(true)}
                ModalTakedown={() => setShowModalTakedown(true)}
                ModalRestore={() => setShowModalRestore(true)}
                ModalResolved={() => setShowModalResolved(true)}
            />
            <ModalTakedown onConfirm={takedownThreat} isOpen={showModalTakedown} onClose={() => setShowModalTakedown(false)} />
            <ModalApproval onConfirm={approvalThreat} isOpen={showModalApproval} onClose={() => setShowModalApproval(false)} />
            <ModalDiscard onConfirm={discardThreat} isOpen={showModalDiscard} onClose={() => setShowModalDiscard(false)} />
            <ModalQuarantine onConfirm={quarantineThreat} isOpen={showModalQuarantine} onClose={() => setShowModalQuarantine(false)} />
            <ModalResolved onConfirm={resolvedThreat} isOpen={showModalResolved} onClose={() => setShowModalResolved(false)} />
            <ModalNewThreats setShowSnackNewThreat={setShowSnackNewThreat} isOpen={showModalNewThreats} onClose={() => setShowModalNewThreats(false)} />
            <ModalCancelApproval onConfirm={cancelThreatApproval} isOpen={showModalCancelApproval} onClose={() => setShowModalCancelApproval(false)} />
            <ModalRestore onConfirm={restoreLast} isOpen={showModalRestore} onClose={() => setShowModalRestore(false)} />
            <ModalEdit onConfirm={editThreat} isOpen={showModalEdit} onClose={() => setShowModalEdit(false)} />
            <Modal width={480} isOpen={showModalBBCta} onClose={() => navigate('/manage-threats')} title="Contratar Brand Bidding" subtitle="Você não tem acesso ao módulo.">
                <br />
                <Body style="medium">Tenha controle sobre os Agressores que afetam sua empresa.</Body>
                <br />
                <div className="mt-2"></div>
                <Body>
                    Contate um dos nossos atendentes o quanto antes, para que você possa controlar, monitorar e
                    <Body style="medium">&nbsp;Eliminar&nbsp;</Body>
                    os agressores que
                    <Body style="medium">&nbsp;roubam&nbsp;</Body>
                    seus lucros!
                </Body>
                <br />
                <br />

                <p>
                    <Body style="medium">Entre em contato com seu atendimento!</Body>
                </p>
                <br />
            </Modal>


            <Snackbar setVisible={setShowSnackNewThreat} visible={showSnackNewThreat} state="success">
                <Label style="medium">Ameaça criada com sucesso!</Label> - Foi incluída manualmente na tabela a ameaça {current?.url}.
            </Snackbar>
            <Snackbar setVisible={setShowSnackTakedown} visible={showSnackTakedown} state="success">
                <Label style="medium">Takedown solicitado com sucesso!</Label> -
                {
                    threatsSelected > 1
                        ? `A solicitação de takedown das ${threatsSelected} ameaças foram enviadas e movidas para “Aguardando aprovação”.`
                        : `A solicitação de takedown da ameaça ${current?.url} foi enviada e movida para “Aguardando aprovação”.`
                }
            </Snackbar>
            <Snackbar setVisible={setShowSnackAutomaticApproval} visible={showSnackAutomaticApproval} state="success">
                <Label style="medium">Takedown solicitado com sucesso!</Label> -
                {
                    threatsSelectedAutomaticApproval > 1
                        ? `Aprovação automática moveu ${threatsSelectedAutomaticApproval} ameaças para "Em tratativa".`
                        : `Aprovação automática moveu ${current?.url} para "Em tratativa".`
                }
            </Snackbar>
            <Snackbar setVisible={setShowSnackDiscarded} visible={showSnackDiscarded} state="info">
                <Label style="medium">Descartado com sucesso!</Label> -
                {threatsSelected > 1
                    ? `O total de ${threatsSelected} ameaças foram movidas para a aba "Descartadas".`
                    : `A ameaça ${current?.url} foi movida para a aba "Descartadas".`
                }
            </Snackbar>
            <Snackbar setVisible={setShowSnackQuarantine} visible={showSnackQuarantine} state="info">
                <Label style="medium">Movido para Quarentena!</Label> -
                {threatsSelected > 1
                    ? `As ${threatsSelected} ameaças foram movidas para "Quarentena"`
                    : `A ameaça ${current?.url} foi movida para "Quarentena".`}
            </Snackbar>
            <Snackbar setVisible={setShowSnackResolved} visible={showSnackResolved} state="info">
                <Label style="medium">Resolvida com sucesso!</Label> -
                {threatsSelected > 1
                    ? `Foi marcado como resolvido ${threatsSelected} ameaças.`
                    : `Foi marcado como resolvido a ameaça ${current?.url}.`
                }
            </Snackbar>
            <Snackbar setVisible={setShowSnackApproval} visible={showSnackApproval} state="info">
                <Label style="medium">Aprovação solicitada com sucesso!</Label> -
                {threatsSelected > 1
                    ? `As ${threatsSelected} ameaças foram movidas para "Aguardando aprovação".`
                    : `A ameaça ${current?.url} foi movida para "Aguardando aprovação".`}
            </Snackbar>
            <Snackbar setVisible={setShowSnackCancelApproval} visible={showSnackCancelApproval} state="info">
                <Label style="medium">Aprovação cancelada com sucesso!</Label> -
                {threatsSelected > 1
                    ? `A solicitação de aprovação de ${threatsSelected} ameaças foram canceladas e movidas para a aba "Novas".`
                    : `A solicitação de aprovação da ameaça ${current?.url} foi cancelada e movida para a aba "Novas".`
                }
            </Snackbar>
            <Snackbar setVisible={setShowSnackRestore} visible={showSnackRestore} state="info">
                <Label style="medium">Restaurado com sucesso!</Label> -
                {threatsSelected > 1
                    ? `As ${threatsSelected} ameaças foram restauradas e voltaram para a aba de seus estados anteriores`
                    : `A ameaça ${current?.url} foi restaurada e voltou para a aba de seu estado anterior.`
                }
            </Snackbar>
            <Snackbar setVisible={setShowSnackFraudStatus} visible={showSnackFraudStatus} state="info">
                <Label style="medium">Status da ameaça alterado com sucesso!</Label>
            </Snackbar>
            <Snackbar setVisible={setShowSnackEdit} visible={showSnackEdit} state="info">
                <Label style="medium">Ameaça editada!</Label> - A ameaça "{current?.url}" foi editada e as alterações salvas.
            </Snackbar>
        </div >
    )
}



const P = styled.p`
    margin-bottom: 0;
    font-size: 16px;
    min-width: 200px
`;

const P2 = styled.p`
    margin-bottom: 0;
    font-size: 16px;
`;

const FilesCard = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
`