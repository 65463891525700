import Input from "../../../../DesignSystem/components/Input/Input";
import Label from "../../../../DesignSystem/components/Text/Label";
import { ThreatPlatformEnum } from "../../../../enums/threat-platform.enum";
import Select from "../../../../DesignSystem/components/Select";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import ButtonPrimary from "../../../../DesignSystem/components/Button/ButtonPrimary";
import { AddOutlined, CalendarMonth, DeleteOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { addOccurrenceProfileForm, changeOccurenceProfileForm, changeProfileFormEvidenceFiles, changeProfileFormEvidenceLink, changeProfileFormIsActive, changeProfileFormPlatform, changeProfileFormProfile, changeProfileFormSearchDate, changeProfileFormUrl, removeOccurrenceProfileForm } from "../../../../store/threats/actions";
import InputEvidence from "../../../../DesignSystem/components/Input/InputEvidence";
import InputDate from "../../../../DesignSystem/components/Input/InputDate";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";
import { dsVariables } from "../../../../DesignSystem/variables";
import { useState } from "react";
import Body from "../../../components/Text/Body";
import Accordion from "../../../components/Accordion";

interface IProps {
    setTypeForm?: any
    isInvalidUrl: boolean
}

export default function ProfileForm({ setTypeForm, isInvalidUrl }: IProps) {
    const dispatch = useDispatch()

    const { profileForm } = useSelector((store: IStore) => store.Threats.forms)

    const occurrences = useSelector((state: IStore) => state.Threats.forms.profileForm?.occurrences || []);

    function getAccordionBorderType(index: number): 'top' | 'bottom' | 'top-bottom' | any {
        if (occurrences.length == 1 || occurrences.length == 0) return

        if (occurrences.length == 2) return index == 0 ? 'bottom' : 'top'
        else {
            if (index == 0) return 'bottom'
            else if (index == occurrences.length - 1) return 'top'
            else return 'top-bottom'
        }
    }

    return (
        <form className="mt-2" >
            <div className="mb-3 d-flex flex-column gap-4">
                <div className="d-flex gap-3">
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Tipo de ameaça*</Label>
                        <Select state={setTypeForm ? 'default' : 'disabled'} width={338} selected={ThreatTypeTextEnum.FAKE_PROFILE} required onChange={(e: any) => setTypeForm(e.target.value)} options={Object.values(ThreatTypeTextEnum).filter(el => el != ThreatTypeTextEnum.ALL)} />
                    </div>

                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Perfil*</Label>
                        <Input state={setTypeForm ? 'default' : 'disabled'} width={338} placeholder="Ex: @wadewilson" required value={profileForm.profile} onChange={(e: any) => dispatch(changeProfileFormProfile(e.target.value))} />
                    </div>
                </div>
                <div className="d-flex gap-3">
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Plataforma*</Label>
                        <Select state={setTypeForm ? 'default' : 'disabled'} width={221} required onChange={(e: any) => dispatch(changeProfileFormPlatform(e.target.value))} options={Object.values(ThreatPlatformEnum).filter(el => el == ThreatPlatformEnum.FACEBOOK || el == ThreatPlatformEnum.INSTAGRAM || el == ThreatPlatformEnum.TIKTOK)} />
                    </div>
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Status*</Label>
                        <Select selected={profileForm.isActive ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE} width={221} required onChange={(e: any) => dispatch(changeProfileFormIsActive(e.target.value == ThreatStatusActiveEnum.ACTIVE))} options={Object.values(ThreatStatusActiveEnum)} />
                    </div>
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Criação do perfil</Label>
                        <div style={{ pointerEvents: setTypeForm ? 'auto' : 'none' }} className="d-flex align-items-stretch  position-relative">
                            <InputDate state={setTypeForm ? 'default' : 'disabled'} width={221} value={profileForm.searchDate} onChange={date => dispatch(changeProfileFormSearchDate(date[0]))} />
                            <div className="position-absolute pe-none end-0 top-0">
                                <ButtonPrimary state={setTypeForm ? 'default' : 'disabled'} onClick={() => null} iconLeft={CalendarMonth} iconLeftColor="white" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column gap-1">
                        <Body style="medium">Ocorrências</Body>
                        <Label color={dsVariables.colors.Gray[600]}>* As urls de cada ocorrência devem pertencer ao mesmo domínio.</Label>
                    </div>
                    <div className="">
                        <ButtonPrimary onClick={(e: any) => dispatch(addOccurrenceProfileForm())} iconLeft={AddOutlined} iconLeftColor="white" label="Adicionar ocorrência" />
                    </div>
                </div>

                <div className="d-flex flex-column">

                    {
                        occurrences.map((occurrence, index) =>
                            <Accordion
                                defaultOpened={occurrences.length == 1 && true}
                                borderType={getAccordionBorderType(index)}
                                withBorder
                                headerIcon={occurrences.length > 1 ? DeleteOutlined : null} headerIconColor={dsVariables.colors.Red[500]}
                                headerIconOnClick={() => dispatch(removeOccurrenceProfileForm(index))} key={index}
                                title={`${index + 1}. Ocorrência`}>
                                <div key={index} className="d-flex gap-2 align-items-center">
                                    <div className="d-flex flex-column gap-2">
                                        <div className="d-flex gap-4">
                                            <div className="d-flex flex-column gap-2 ">
                                                <Label color={dsVariables.colors.Gray[800]}>URL da ocorrência*</Label>
                                                <Input state={setTypeForm ? 'default' : 'disabled'} error={isInvalidUrl} width={656} required placeholder="Insira a URL" value={occurrence?.url}
                                                    onChange={(e: any) => {
                                                        const url = (e.target.value)
                                                        const updatedOccurrence = { ...occurrence, url }
                                                        dispatch(changeOccurenceProfileForm({ updatedOccurrence, index }))
                                                    }} />
                                            </div>
                                        </div>
                                        <InputEvidence
                                            files={occurrence?.evidence?.files}
                                            onChangeLink={(e: any) => {
                                                const link = (e.target.value)
                                                const updatedOccurrence = { ...occurrence, evidence: { ...occurrence.evidence, link } }
                                                dispatch(changeOccurenceProfileForm({ updatedOccurrence, index }))
                                            }}
                                            linkValue={occurrence?.evidenceLink}
                                            onDropFile={(e: any) => {
                                                const newEvidence = e[0]
                                                const updatedOccurrence = { ...occurrence, evidence: { ...occurrence.evidence, files: [...occurrence.evidence.files, newEvidence] } }
                                                dispatch(changeOccurenceProfileForm({ updatedOccurrence, index }))
                                            }}
                                            onRemoveFile={(file: File) => {
                                                const updatedOccurrenceFiles = occurrence?.evidence?.files.filter((el: any) => el != file)
                                                const updatedOccurrence = { ...occurrence, evidence: { ...occurrence.evidence, files: updatedOccurrenceFiles } }
                                                dispatch(changeOccurenceProfileForm({ updatedOccurrence, index }))
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <AddOutlined onClick={(e: any) => dispatch(addOccurrenceProfileForm())} />
                                        {occurrences.length > 1 && <DeleteOutlined onClick={(e: any) => dispatch(removeOccurrenceProfileForm(index))} />}
                                    </div>
                                </div>
                            </Accordion>)
                    }
                </div>
            </div>
        </form>
    )
}