export enum FinancialInstitutionEnum {
    NONE= "Nenhum",
    Banco_Brasil = "Banco do Brasil (BB)",
    Caixa_Economica = "Caixa Econômica Federal(CEF)",
    Banco_Bradesco = "Banco Bradesco(BBDC)",
    Banco_Itau = "Banco Itaú Unibanco(ITUB)",
    Banco_Santander = "Banco Santander Brasil(BSBR)",
    Banco_Inter = "Banco Inter(BIDI)",
    PagBank = "PagBank(PagSeguro)",
    PagarME = "Pagar.me Pagamentos S.A",
    Agibank = "Agibank",
    Banco_Sicredi = "Banco Sicredi",
}