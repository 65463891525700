export enum ThreatOptionsCardEnum {
    TAKEDOWN = 'takedown',
    QUARANTINE = 'quarantine',
    DISCARD = 'discard',
    RESOLVED = 'resolved',
    APPROVAL = 'approval',
    NEW = 'new',
    RESTORE = 'restore',
    EDIT = 'edit',
    REGISTER_NOTIFICATION = 'REGISTER_NOTIFICATION',
    ASSUME_TAKEDOWN = 'ASSUME_TAKEDOWN',
    RENOUNCE_TAKEDOWN = 'RENOUNCE_TAKEDOWN',
    SPECIAL_TREATMENT = 'SPECIAL_TREATMENT',
    FINISH = 'FINISH',
    REOPEN_THREAT = 'REOPEN_THREAT',
}