
import { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "react-bootstrap";
import { dsVariables } from "../../variables";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/store.interface";
import { Input } from "reactstrap";
import SimpleBar from "simplebar-react";

export default function CompaniesDropdownAdmin({ selectedCompany, handleCompany, userType, updateCompaniesSelected, companiesSelected }: any) {
    const { companies, user } = useSelector((store: IStore) => ({
        companies: store.Admin.companiesList,
        user: store.Admin.user,
    }))

    const [companyFilterByName, setCompanyFilterByName] = useState("");

    const filteredAndSortedCompanies = companies?.slice().sort((a, b) => {
        // A empresa selecionada fica no topo da lista.
        if (a.name === companies.find(el => el._id == selectedCompany)?.name) return -1;
        if (b.name === companies.find(el => el._id == selectedCompany)?.name) return 1;
        return a.name.localeCompare(b.name);
    }).filter(el =>
        !companyFilterByName ||
        new RegExp(companyFilterByName, "i").test(el.name))

    const handleSelectedCompanyFilter = (value: string) => {
        setCompanyFilterByName(value);
    };

    return (
        <Dropdown>
            <DropdownToggle
                style={{ color: dsVariables.colors.Astronaut[400] }}
                className="form-control text-start bg-white text-black"
            >
                <span>{companies.find(el => el._id == selectedCompany)?.name || companies[0]?.name}</span>
            </DropdownToggle>

            <DropdownMenu className="form-control">
                <div className="py-3 px-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                    <div className="form-icon">
                        <Input
                            className="form-control form-control-icon"
                            id="searchBrands"
                            placeholder="Pesquisar nome da empresa"
                            onChange={(event) =>
                                handleSelectedCompanyFilter(event.target.value)
                            }
                            value={companyFilterByName}
                        />
                        <i className="ri-search-line"></i>
                    </div>
                </div>

                <SimpleBar style={{ maxHeight: "260px" }}>
                    {
                        filteredAndSortedCompanies?.map((company, index) =>
                            ((company._id === selectedCompany || Boolean(companiesSelected.find((el: string) => el == company._id)))) ?
                                <DropdownItem
                                    defaultValue={user?.customerCompaniesList && user?.customerCompaniesList[0]}
                                    className="notify-item bg-primary text-white" key={index}
                                    onClick={(e) => {
                                        if (userType == 'customer') handleCompany({ target: { value: company._id } })
                                        else {
                                            e.stopPropagation()
                                            updateCompaniesSelected(company._id)
                                        }
                                    }}
                                >
                                    <span className="align-middle">{company.name}</span>
                                </DropdownItem>
                                :
                                <DropdownItem
                                    className="notify-item"
                                    key={index}
                                    id={company._id}
                                    onClick={(e) => {
                                        if (userType == 'customer') handleCompany({ target: { value: company._id } })
                                        else {
                                            e.stopPropagation()
                                            updateCompaniesSelected(company._id)
                                        }
                                    }}
                                >
                                    <span className="align-middle">{company.name}</span>
                                </DropdownItem>

                        )
                    }
                </SimpleBar>
            </DropdownMenu>
        </Dropdown >
    )
}