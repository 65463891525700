import { useFormik } from "formik";
import { t } from "i18next";
import { useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Input, Label } from "reactstrap";
import * as Yup from "yup";
import { dsVariables } from "../../../variables";
import { changeUserPassword } from "../../../../store/admin/action";

interface IProps {
    userId: string
    closeModal: any
}

export default function ChangePasswordForm({ userId, closeModal }: IProps) {

    const dispatch = useDispatch();

    const [isMinLength, setIsMinLength] = useState(false);
    const [isLowercase, setIsLowercase] = useState(false);
    const [isUppercase, setIsUppercase] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    const [isPasswordEqual, setIsPasswordEqual] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

    const isButtonDisabled = !isMinLength || !isUppercase || !isLowercase || !isNumber || !isSpecial || !isPasswordEqual

    const passwordValidation = Yup.string()
        .required('Password is required')
        .test('password-rules', 'Invalid password', function (value) {
            const isValid = !!value;

            setIsMinLength(isValid && value.length >= 8);
            setIsUppercase(isValid && /[A-Z]/.test(value));
            setIsLowercase(isValid && /[a-z]/.test(value));
            setIsNumber(isValid && /\d/.test(value));
            setIsSpecial(isValid && /[@$!%*#?&]/.test(value));
            return true;
        })

    const confirmPasswordValidation = Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm password is required')
        .test('password-match', 'Passwords do not match', function (value) {
            setIsPasswordEqual(value === this.resolve(Yup.ref('password')));
            return true;
        });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: "",
            confirm_password: "",
        },

        validationSchema: Yup.object({
            password: passwordValidation,
            confirm_password: confirmPasswordValidation,
        }),

        onSubmit: (values) => {
            dispatch(changeUserPassword({ userId, password: values.password }));
            closeModal()
        },
    });

    const handlePasswordChange = (e: any) => {
        const { value } = e.target;
        validation.handleChange(e);

        if (!value) {
            setIsMinLength(false);
            setIsUppercase(false);
            setIsLowercase(false);
            setIsNumber(false);
            setIsSpecial(false);
            setIsPasswordEqual(false)
        }
    };

    const handleConfirmPasswordChange = (e: any) => {
        validation.handleChange(e);

        setIsPasswordEqual(e.target.value === validation.values.password);
    };

    return (
        <Form onSubmit={validation.handleSubmit} className="pb-3">
            <div className="mb-3">
                <Label className="form-label" htmlFor="password-input">
                    {t("page.authentication.password-create.label.password")}
                </Label>
                <div className="position-relative auth-pass-inputgroup mb-2">
                    <Input
                        type={passwordShow ? "text" : "password"}
                        className="form-control pe-5 password-input"
                        placeholder={t(
                            "page.authentication.password-create.placeholder.password"
                        )}
                        id="password-input"
                        name="password"
                        onBlur={validation.handleBlur}
                        onChange={handlePasswordChange}
                        value={validation.values.password}
                    />
                    <Button
                        color="link"
                        style={{ backgroundColor: dsVariables.colors.Astronaut[700] }}
                        onClick={() => setPasswordShow(!passwordShow)}
                        className="position-absolute end-0 top-0 text-decoration-none password-addon border-0"
                        id="password-addon"
                    >
                        <i className="ri-eye-fill align-middle"></i>
                    </Button>
                </div>

                <div
                    id="passwordInput"
                    className={`${isMinLength
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                >
                    {isMinLength
                        ? <i className="ri-checkbox-circle-line align-middle me-1"></i>
                        : <i className="ri-close-circle-line align-middle me-1"></i>
                    }
                    {t("page.authentication.password-create.rule.min-characters")}
                </div>

                <div
                    id="passwordInput"
                    className={`${isUppercase
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                >
                    {isUppercase
                        ? <i className="ri-checkbox-circle-line align-middle me-1"></i>
                        : <i className="ri-close-circle-line align-middle me-1"></i>
                    }
                    {t("page.authentication.password-create.rule.uppercase-letter")}
                </div>

                <div
                    id="passwordInput"
                    className={`${isLowercase
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                >
                    {isLowercase
                        ? <i className="ri-checkbox-circle-line align-middle me-1"></i>
                        : <i className="ri-close-circle-line align-middle me-1"></i>
                    }
                    {t("page.authentication.password-create.rule.lowercase-letter")}
                </div>

                <div
                    id="passwordInput"
                    className={`${isNumber
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                >
                    {isNumber
                        ? <i className="ri-checkbox-circle-line align-middle me-1"></i>
                        : <i className="ri-close-circle-line align-middle me-1"></i>
                    }
                    {t("page.authentication.password-create.rule.number")}
                </div>

                <div
                    id="passwordInput"
                    className={`${isSpecial
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                >
                    {isSpecial
                        ? <i className="ri-checkbox-circle-line align-middle me-1"></i>
                        : <i className="ri-close-circle-line align-middle me-1"></i>
                    }
                    {t("page.authentication.password-create.rule.special-character")}
                </div>

                <div
                    id="passwordInput"
                    className={`${isPasswordEqual
                        ? "form-text d-flex text-success"
                        : "form-text d-flex"
                        }`}
                >
                    {isPasswordEqual
                        ? <i className="ri-checkbox-circle-line align-middle me-1"></i>
                        : <i className="ri-close-circle-line align-middle me-1"></i>
                    }
                    {t("page.authentication.password-create.equal.password")}
                </div>
            </div>

            <div className="mb-3">
                <Label className="form-label" htmlFor="confirm-password-input" >
                    {t("page.authentication.password-create.label.password-confirm")}
                </Label>
                <div className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                        type={confirmPasswordShow ? "text" : "password"}
                        className="form-control pe-5 password-input"
                        placeholder={t(
                            "page.authentication.password-create.placeholder.password-confirm"
                        )}
                        id="confirm-password-input"
                        name="confirm_password"
                        onBlur={validation.handleBlur}
                        onChange={handleConfirmPasswordChange}
                        value={validation.values.confirm_password}
                    />
                    <Button
                        color="link"
                        style={{ backgroundColor: dsVariables.colors.Astronaut[700] }}
                        onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                        className="position-absolute end-0 top-0 text-decoration-none password-addon border-0"
                    >
                        <i className="ri-eye-fill align-middle"></i>
                    </Button>
                </div>
            </div>

            <div className="mt-4">
                <Button
                    disabled={isButtonDisabled}
                    color="secondary"
                    className="w-100 border-0 d-flex justify-content-center align-items-center"
                    type="submit"
                    style={{ backgroundColor: dsVariables.colors.Astronaut[700] }}
                >
                    Resetar senha
                </Button>
            </div>
        </Form>
    )
}