import { AdminInitialState } from "./state";
import { ActionTypesEnum } from "./types.enum";

const adminReducer = (state = AdminInitialState, action: { type: ActionTypesEnum, payload: any }) => {
    switch (action.type) {
        case ActionTypesEnum.FETCH_COMPANIES:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_COMPANIES_LIST:
            return {
                ...state,
                companiesList: action.payload
            };

        case ActionTypesEnum.FETCH_COMPANIES_GROUP:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_COMPANIES_GROUP_LIST:
            return {
                ...state,
                companiesGroupList: action.payload
            };

        case ActionTypesEnum.INVITE_USER:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER_PERMISSIONS:
            return {
                ...state,
            };

        case ActionTypesEnum.FETCH_USER_TO_CHANGE:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER:
            return {
                ...state,
                user: action.payload
            };

        case ActionTypesEnum.FETCH_USERS:
            return {
                ...state,
            };

        case ActionTypesEnum.FETCH_USERS_AND_INVITEDS:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USERS:
            return {
                ...state,
                users: action.payload
            };

        case ActionTypesEnum.CHANGE_USERS_AND_INVITEDS:
            return {
                ...state,
                usersAndInviteds: {
                    ...state.usersAndInviteds,
                    data: action.payload.data,
                    totalPages: action.payload.totalPages,
                    totalResults: action.payload.totalResults
                }
            };

        case ActionTypesEnum.CHANGE_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload
            };

        case ActionTypesEnum.CHANGE_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            };

        case ActionTypesEnum.CANCEL_USER_INVITE:
            return {
                ...state,
            };

        case ActionTypesEnum.RESEND_USER_INVITE:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER_PASSWORD:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER_STATUS:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_FILTER:
            return {
                ...state,
                usersAndInviteds: {
                    ...state.usersAndInviteds,
                    filter: action.payload
                }
            };

        default:
            return state;
    }
};
export default adminReducer;