import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import Body from "../../../components/Text/Body";
import Sub6 from "../../../components/Text/Sub6";
import Label from "../../../components/Text/Label";
import { Add, CloseOutlined } from "@mui/icons-material";
import Accordion from "../../../components/Accordion";
import EmptyState from "../../../assets/empty-state-complaints.svg"
import { getThreatTypeText } from "../../../utils/get-threat-type-text.util";
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import styled from "styled-components";
import { dsVariables } from "../../../variables";
import { useEffect, useState } from "react";
import RegisterComplaintForm from "../Forms/RegisterComplaintForm";
import { changeComplaintFormChannel, changeComplaintFormDate, changeComplaintFormEvidence, changeComplaintFormEvidenceLink, changeComplaintFormReportedId, changeComplaintFormReportedTo, changePixFormCheckoutUrl, changePixFormCpf, changePixFormEvidence, changePixFormEvidenceLink, changePixFormFinancialInstitution, changePixFormPaymentGateway, changePixFormPix, fetchComplaints, fetchPix } from "../../../../store/threats/actions";
import { CompaniesComplaintEnum } from "../../../../enums/companies-complaint.enum";
import RegisterPixForm from "../Forms/RegisterPixForm";
import { FinancialInstitutionEnum } from "../../../../enums/financial-institution.enum";
import { PaymentsGatewayEnum } from "../../../../enums/payments-gateway.enum";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader";
import Icon from "../../../components/Icon";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSearchParams } from "react-router-dom";

interface IProps {
    onClose: any
}

export default function ModalTakedownDetail({ onClose }: IProps) {

    const searchParams = useSearchParams()[0]

    const tab = searchParams.get('tab')

    const isDetailView = tab != '0' && tab != '1'

    const { currentTakedown, isLoading } = useSelector((store: IStore) => store.Threats)

    const [showRegisterComplaint, setShowRegisterComplaint] = useState(false);
    const [showRegisterPix, setShowRegisterPix] = useState(false);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchComplaints(currentTakedown?._id!))
        dispatch(fetchPix(currentTakedown?.threat?._id!))
    }, []);

    function getAccordionBorderType(array: any[], index: number): 'top' | 'bottom' | 'top-bottom' | any {
        if (array.length == 1 || array.length == 0) return

        if (array.length == 2) return index == 0 ? 'bottom' : 'top'
        else {
            if (index == 0) return 'bottom'
            else if (index == array.length - 1) return 'top'
            else return 'top-bottom'
        }
    }

    return (
        <section className="w-100 h-100 position-fixed overflow-hidden top-0 start-0" style={{ zIndex: 12312312 }}>
            {isLoading && <Loader />}
            <div onClick={onClose} className="w-100 h-100 position-absolute top-0 start-0" style={{ opacity: 0.4, zIndex: 0, background: 'black' }}></div>
            <section className="w-100 h-100 bg-white mt-5 position-relative pb-5" style={{ borderRadius: 8, padding: 16, overflow: 'auto' }}>
                <div className="d-flex flex-column gap-2 mb-4">
                    <Sub6>{isDetailView ? 'Detalhes da tratativa' : 'Registrar denúncia'}</Sub6>
                    <Label color="#516EE8"><span className="text-decoration-underline">{currentTakedown?.sourceThreat.searchText}</span></Label>
                </div>

                <div style={{ gap: 12, display: 'flex' }}>
                    <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ gap: 16, display: 'flex', flexDirection: 'column', borderBottom: '1px solid #E6E6E6', paddingBottom: 8 }}>
                            <Sub6 style="medium">Sobre a ameaça</Sub6>
                            <button disabled={isDetailView} role={isDetailView ? "none" : "button"} onClick={() => setShowRegisterPix(true)} className="bg-transparent border-0 m-0 p-0 text-start">
                                <Label color={isDetailView ? dsVariables.colors.Gray[400] :"#30437F"}><Add /> Add evidência (Pix)</Label>
                            </button>
                            <Body color="#818181" style="medium">Detalhes da ameaça</Body>
                        </div>
                        {showRegisterPix && <div className="gap-4" style={{ marginTop: 8, paddingBlock: 16, overflow: 'auto', display: 'flex', flexDirection: 'column', }}>
                            <RegisterPixForm onCancel={() => {
                                setShowRegisterPix(false)
                                dispatch(changePixFormEvidenceLink(''))
                                dispatch(changePixFormEvidence([]))
                                dispatch(changePixFormCheckoutUrl(''))
                                dispatch(changePixFormCpf(''))
                                dispatch(changePixFormFinancialInstitution(FinancialInstitutionEnum.NONE))
                                dispatch(changePixFormPaymentGateway(PaymentsGatewayEnum.NONE))
                                dispatch(changePixFormPix(''))
                            }} />
                        </div>
                        }
                        {!!currentTakedown?.pix &&
                            <div style={{ paddingBlock: 16 }} className="d-flex flex-column gap-4">
                                {
                                    currentTakedown?.pix?.map(pix =>
                                        <RegisterPixForm onCancel={() => setShowRegisterPix(false)} pix={pix} key={pix._id} editFlow={true} />
                                    )
                                }
                            </div>
                        }
                        <div style={{ padding: 16, overflow: 'auto', border: '1px solid #E6E6E6', display: 'flex', flexDirection: 'column' }}>
                            <div className="d-flex gap-4 border-b-1 border-secondary">
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Tipo de ameaça</Body>
                                        <Body color="#4d4d4d">{getThreatTypeText(currentTakedown?.threat.type!)}</Body>
                                    </div>
                                    <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Data de identificação</Body>
                                        <Body color="#4d4d4d">{new Date(currentTakedown?.createdAt!).toLocaleDateString('pt-BR')}</Body>
                                    </div>
                                    {currentTakedown?.sourceThreat.profile && <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Perfil</Body>
                                        <Body color="#4d4d4d">{currentTakedown?.sourceThreat.profile}</Body>
                                    </div>}
                                </div>
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Status</Body>
                                        <Body color="#4d4d4d">{currentTakedown?.isActive ? 'Ativo' : 'Inativo'}</Body>
                                    </div>
                                    {currentTakedown?.sourceThreat.platform && <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Plataforma</Body>
                                        <Body color="#4d4d4d">{currentTakedown?.sourceThreat.platform}</Body>
                                    </div>}
                                    {currentTakedown?.sourceThreat.advertiserId && <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">ID do anúncio</Body>
                                        <Body color="#4d4d4d">{currentTakedown?.sourceThreat.advertiserId}</Body>
                                    </div>}
                                </div>
                            </div>
                            {currentTakedown?.threat.type == ThreatTypeEnum.FAKE_SITE && <div className="d-flex flex-column mt-4">
                                <Accordion title="Informações do Domínio">
                                    <div className="d-flex flex-column gap-2">
                                        <div className="d-flex flex-column gap-2">
                                            <div className="d-flex gap-2">
                                                <Label style="medium">Domain Name:</Label>
                                                <Label>{currentTakedown?.threat.threatDetails?.domainInfo?.id}</Label>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <Label style="medium">Registry Date UTC:</Label>
                                                {currentTakedown?.threat.threatDetails?.domainInfo?.createdDate && <Label>{new Date(currentTakedown?.threat.threatDetails?.domainInfo?.createdDate).toLocaleDateString('pt-BR')}&nbsp;{new Date(currentTakedown?.threat.threatDetails?.domainInfo?.createdDate).toLocaleTimeString('pt-BR')}</Label>}
                                            </div>
                                            <div className="d-flex gap-2">
                                                <Label style="medium">Registry Domain ID:</Label>
                                                <Label>{currentTakedown?.threat.threatDetails?.domainInfo?.id}</Label>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <Label style="medium">Status:</Label>
                                                {currentTakedown?.threat.threatDetails?.domainInfo?.status.map((el: any, index: number) => <Label key={index}>{el}</Label>)}
                                            </div>
                                        </div>
                                    </div>
                                </Accordion>
                                <Accordion title="Informações do Registrar">
                                    <div className="d-flex flex-column gap-2">

                                        <div className="d-flex gap-2">
                                            <Label style="medium">IANA Number:</Label>
                                            <Label>{currentTakedown?.threat.threatDetails?.domainInfo?.name}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Entity:</Label>
                                            <Label>{currentTakedown?.threat.threatDetails?.hostInfo?.slice(-1)[0]?.organization}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Abuse Contact Phone:</Label>
                                            <Label>{currentTakedown?.threat.threatDetails?.hostInfo?.slice(-1)[0]?.organization}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Abuse Contact Email:</Label>
                                            <Label>{currentTakedown?.threat.threatDetails?.hostInfo?.slice(-1)[0]?.abuseEmail}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Entity Website:</Label>
                                            <Label>{currentTakedown?.threat.threatDetails?.hostInfo?.slice(-1)[0]?.organization}</Label>
                                        </div>
                                    </div>

                                </Accordion>
                                <Accordion title="Informações da Hospedagem">
                                    <div className="d-flex flex-column gap-2">

                                        <div className="d-flex gap-2">
                                            <Label style="medium">Net Range:</Label>
                                            <Label>{currentTakedown?.threat.threatDetails?.hostInfo?.slice(-1)[0]?.ipRange}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Net Name:</Label>
                                            <Label>{currentTakedown?.threat.threatDetails?.hostInfo?.slice(-1)[0]?.networkName}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Abuse Contact Email:</Label>
                                            <Label>{currentTakedown?.threat.threatDetails?.hostInfo?.slice(-1)[0]?.abuseEmail}</Label>
                                        </div>
                                    </div>

                                </Accordion>
                                <Accordion title="Evidências por ocorrência">
                                    {currentTakedown?.occurrences?.map((occurrence: any, index: number) =>
                                        <Accordion key={index}
                                            isChild
                                            borderType={getAccordionBorderType(currentTakedown.occurrences, index)}
                                            withBorder
                                            title={`${index + 1}. ${occurrence.url}`}>
                                            <div key={index} className="d-flex flex-column gap-2">
                                                <div className="d-flex gap-4">
                                                    <Label>Evidência(s):</Label>
                                                    <div className="flex-grow-1 d-flex gap-1 flex-column">{
                                                        occurrence.evidence.link
                                                            ? occurrence.evidence.link
                                                            : occurrence.evidence?.files?.map((file: string, index: number) =>
                                                                <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        {/* @ts-ignore */}
                                                                        <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                                                        <div className="d-flex flex-column gap-1">
                                                                            <Label>{`Evidência ${++index}`}</Label>
                                                                        </div>
                                                                    </div>
                                                                    <button className="border-0 bg-transparent">
                                                                        <a href={file} target="blank" >
                                                                            <Icon IconComponent={OpenInNewIcon} color={dsVariables.colors.Astronaut[700]} />
                                                                        </a>
                                                                    </button>
                                                                </FilesCard>
                                                            )}
                                                    </div>
                                                    {(!occurrence.evidence.link && occurrence.evidence?.files.length == 0) &&
                                                        <Label style="medium">Nenhum evidência cadastrada.</Label>
                                                    }
                                                </div>
                                            </div>
                                        </Accordion>
                                    )}
                                    {
                                        currentTakedown?.pixOccurrences?.map((pixOccurrence: any, index: number) =>
                                            <Accordion key={index}
                                                isChild
                                                borderType={getAccordionBorderType(currentTakedown?.pixOccurrences!, index)}
                                                withBorder
                                                title={`${currentTakedown.occurrences.length + (index + 1)}. ${pixOccurrence.pix}`}>
                                                <div key={index} className="d-flex flex-column gap-2">
                                                    {/* <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">CPF:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.cpf}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Instituição financeira:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.financialInstitution}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Url de checkout:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.checkoutUrl}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Gateway de pagamento:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.paymentGateway}</Label>
                                                    </div> */}

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Evidência(s):</Label>
                                                        </div>
                                                        <div className="flex-grow-1 gap-3 d-flex flex-column">
                                                            {pixOccurrence.evidence.link
                                                                ? pixOccurrence.evidence.link
                                                                : pixOccurrence.evidence?.files?.map((file: string, index: number) => {
                                                                    return (
                                                                        <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                                                            <div className="d-flex gap-2 align-items-center">
                                                                                {/* @ts-ignore */}
                                                                                <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                                                                <div className="d-flex flex-column gap-1">
                                                                                    <Label>{`Evidência ${++index}`}</Label>
                                                                                </div>
                                                                            </div>
                                                                            <button className="border-0 bg-transparent">
                                                                                <a href={file} target="blank" >
                                                                                    <Icon IconComponent={OpenInNewIcon} color={dsVariables.colors.Astronaut[700]} />
                                                                                </a>
                                                                            </button>
                                                                        </FilesCard>
                                                                    )
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion>
                                        )
                                    }
                                    {!currentTakedown?.occurrences?.length && 'Nenhuma evidência inserida'}
                                </Accordion>
                            </div>}
                            {currentTakedown?.threat.type == ThreatTypeEnum.FAKE_AD && <div className="d-flex flex-column mt-4">
                                <Accordion title="Informações do Anunciante">
                                    <div className="d-flex flex-column gap-2">
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Perfil Anunciante:</Label>
                                            <Label>{currentTakedown?.sourceThreat.profile}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">ID Perfil Facebook:</Label>
                                            <Label>{currentTakedown?.sourceThreat.advertiserId}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Perfil Instagram:</Label>
                                            <Label>{currentTakedown?.sourceThreat.instagramProfile}</Label>
                                        </div>
                                    </div>
                                </Accordion>
                            </div>}
                        </div>
                    </div>
                    <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ gap: 16, display: 'flex', flexDirection: 'column', borderBottom: '1px solid #E6E6E6', paddingBottom: 8 }}>
                            <Sub6 style="medium">Denúncias</Sub6>
                            <button disabled={isDetailView} role={isDetailView ? "none" :"button"} onClick={() => setShowRegisterComplaint(true)} className="bg-transparent border-0 m-0 p-0 text-start">
                                <Label color={isDetailView ? dsVariables.colors.Gray[400] :"#30437F"}><Add /> Registrar denúncia</Label>
                            </button>
                            <Body color="#818181" style="medium">Denúncias feitas</Body>
                        </div>
                        <div className="gap-4" style={{ marginTop: 8, padding: 16, overflow: 'auto', display: 'flex', flexDirection: 'column', }}>
                            {showRegisterComplaint && <RegisterComplaintForm onCancel={() => {
                                setShowRegisterComplaint(false)
                                dispatch(changeComplaintFormDate(''))
                                dispatch(changeComplaintFormReportedTo(CompaniesComplaintEnum.NONE))
                                dispatch(changeComplaintFormChannel('Formulário'))
                                dispatch(changeComplaintFormReportedId(''))
                                dispatch(changeComplaintFormEvidenceLink(''))
                                dispatch(changeComplaintFormEvidence([]))
                            }} />}
                            {(!currentTakedown?.complaints && !showRegisterComplaint) && <img className="w-100 h-100" src={EmptyState} alt="nenhuma denúncia" />}
                            {!!currentTakedown?.complaints && currentTakedown?.complaints?.map(complaint =>
                                <RegisterComplaintForm onCancel={() => setShowRegisterComplaint(false)} complaint={complaint} key={complaint._id} editFlow={true} />
                            )}
                        </div>
                    </div>
                </div >


                <CloseOutlined onClick={onClose} className="position-absolute top-0 end-0 m-4" role="button" />


            </section >

            <ToastContainer />
        </section >
    )
}


const FilesCard = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
`