import Input from "../../../../DesignSystem/components/Input/Input";
import Label from "../../../../DesignSystem/components/Text/Label";
import Select from "../../../../DesignSystem/components/Select";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { addOccurrenceSiteForm, changeOccurenceSiteForm, changeSiteFormDomain, changeSiteFormEvidenceFiles, changeSiteFormEvidenceLink, changeSiteFormIsActive, changeSiteFormUrl, removeOccurrenceProfileForm, removeOccurrenceSiteForm } from "../../../../store/threats/actions";
import InputEvidence from "../../../../DesignSystem/components/Input/InputEvidence";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";
import { dsVariables } from "../../../../DesignSystem/variables";
import Body from "../../../components/Text/Body";
import { AddOutlined, DeleteOutlined } from "@mui/icons-material";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import Accordion from "../../../components/Accordion";

interface IProps {
    isInvalidUrl: boolean
    setTypeForm?: any
}

export default function SiteForm({ setTypeForm, isInvalidUrl }: IProps) {

    const dispatch = useDispatch()

    const { siteForm } = useSelector((store: IStore) => store.Threats.forms)

    const occurrences = useSelector((state: IStore) => state.Threats.forms.siteForm?.occurrences || []);

    function getAccordionBorderType(index: number): 'top' | 'bottom' | 'top-bottom' | any {
        if (occurrences.length == 1 || occurrences.length == 0) return

        if (occurrences.length == 2) return index == 0 ? 'bottom' : 'top'
        else {
            if (index == 0) return 'bottom'
            else if (index == occurrences.length - 1) return 'top'
            else return 'top-bottom'
        }
    }

    return (
        <form className="mt-2" >
            <div className="mb-3 d-flex flex-column gap-4">
                <div className="d-flex gap-3">
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Tipo de ameaça*</Label>
                        <Select state={setTypeForm ? 'default' : 'disabled'} width={221} selected={ThreatTypeTextEnum.FAKE_SITE} required onChange={(e: any) => setTypeForm(e.target.value)} options={Object.values(ThreatTypeTextEnum).filter(el => el != ThreatTypeTextEnum.ALL)} />
                    </div>
                    <div className="d-flex flex-column gap-2 flex-grow-1" >
                        <Label color={dsVariables.colors.Gray[800]}>Status*</Label>
                        <Select selected={siteForm.isActive ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE} width={221} required onChange={(e: any) => dispatch(changeSiteFormIsActive(e.target.value == ThreatStatusActiveEnum.ACTIVE))} options={Object.values(ThreatStatusActiveEnum)} />
                    </div>
                    <div className="d-flex flex-column gap-2" >
                        <Label color={dsVariables.colors.Gray[800]}>Domínio*</Label>
                        <Input state={setTypeForm ? 'default' : 'disabled'} error={isInvalidUrl} width={221} required placeholder="Insira o domínio" value={siteForm.searchText}
                            onChange={(e: any) => dispatch(changeSiteFormDomain(e.target.value))} />
                    </div>
                </div>

                <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-column gap-1">
                        <Body style="medium">Ocorrências</Body>
                        <Label color={dsVariables.colors.Gray[600]}>* As urls de cada ocorrência devem pertencer ao mesmo domínio.</Label>
                    </div>
                    <div className="">
                        <ButtonPrimary onClick={(e: any) => dispatch(addOccurrenceSiteForm())} iconLeft={AddOutlined} iconLeftColor="white" label="Adicionar ocorrência" />
                    </div>
                </div>

                <div className="d-flex flex-column">
                    {
                        occurrences.map((occurrence, index) =>
                            <Accordion
                                defaultOpened={occurrences.length == 1 && true}
                                borderType={getAccordionBorderType(index)}
                                withBorder
                                headerIcon={occurrences.length > 1 ? DeleteOutlined : null} headerIconColor={dsVariables.colors.Red[500]}
                                headerIconOnClick={() => dispatch(removeOccurrenceSiteForm(index))} key={index}
                                title={`${index + 1}. Ocorrência`}>
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex gap-4">
                                        <div className="d-flex flex-column gap-2 ">
                                            <Label color={dsVariables.colors.Gray[800]}>URL da ocorrência*</Label>
                                            <Input state={setTypeForm ? 'default' : 'disabled'} error={isInvalidUrl} width={656} required placeholder="Insira a URL" value={occurrence?.url}
                                                onChange={(e: any) => {
                                                    const url = (e.target.value)
                                                    const updatedOccurrence = { ...occurrence, url }
                                                    dispatch(changeOccurenceSiteForm({ updatedOccurrence, index }))
                                                }} />
                                        </div>
                                    </div>
                                    <InputEvidence
                                        files={occurrence?.evidence?.files}
                                        onChangeLink={(e: any) => {
                                            const link = (e.target.value)
                                            const updatedOccurrence = { ...occurrence, evidence: { ...occurrence.evidence, link } }
                                            dispatch(changeOccurenceSiteForm({ updatedOccurrence, index }))
                                        }}
                                        linkValue={occurrence?.evidence?.link}
                                        onDropFile={(e: any) => {
                                            const newEvidence = e[0]
                                            const updatedOccurrence = { ...occurrence, evidence: { ...occurrence.evidence, files: [...occurrence.evidence.files, newEvidence] } }
                                            dispatch(changeOccurenceSiteForm({ updatedOccurrence, index }))
                                        }}
                                        onRemoveFile={(file: File) => {
                                            const updatedOccurrenceFiles = occurrence?.evidence?.files.filter((el: any) => el != file)
                                            const updatedOccurrence = { ...occurrence, evidence: { ...occurrence.evidence, files: updatedOccurrenceFiles } }
                                            dispatch(changeOccurenceSiteForm({ updatedOccurrence, index }))
                                        }}
                                    />
                                </div>
                            </Accordion>)
                    }
                </div>

            </div>
        </form >
    )
}