import styled from "styled-components"
import { dsVariables } from "../../../variables"
import { AccessTime, Cancel, Check, DeleteOutline, Edit, EditOutlined, FileCopy, InfoOutlined, PersonRemoveAlt1Outlined, ReportGmailerrorredOutlined, Restore, TrackChangesOutlined, WebAssetOff } from "@mui/icons-material"
import { ThreatOptionsCardEnum } from "../../../../enums/threat-options-card.enum"
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import useCanPerformAction from "../../../hooks/CanPerformAction.hook";
import { ActionEnum } from "../../../constants/user-action-permissions.constant";

interface IProps {
    onClick: any
    operatorView?: boolean
    tableIndex?: number
}

export default function OptionsCard({ onClick, operatorView = false, tableIndex }: IProps) {

    const searchParams = useSearchParams()[0]

    const tab = searchParams.get('tab')

    const { isCustomer, takedownsTableData } = useSelector((store: IStore) => {
        return {
            isCustomer: store.Profile.user.isCustomer,
            takedownsTableData: store.Threats.takedownsTableData,
        }
    })

    console.log(takedownsTableData, tableIndex)

    const canActTakedown = useCanPerformAction(ActionEnum.act_takedown)

    const showRenounce = canActTakedown && tab != '3' && takedownsTableData[tableIndex!].responsibleName

    return !isCustomer
        && <Div tableLength={takedownsTableData.length} tableIndex={tableIndex} operatorView={operatorView} className="d-flex flex-column gap-2 position-absolute">
            {(canActTakedown && !operatorView) && <button onClick={() => onClick(ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                {(tab != '3' && tab != '2') ? <ReportGmailerrorredOutlined /> : <InfoOutlined />}
                <span>{(tab != '3' && tab != '2') ? 'Registrar denúncia' : 'Visualizar detalhes'}</span>
            </button>}
            {((tab == '0' || !tab) && canActTakedown) && <button onClick={() => onClick(ThreatOptionsCardEnum.SPECIAL_TREATMENT)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <TrackChangesOutlined />
                <span>Tratativas especiais</span>
            </button>}
            {((tab != '3' || !tab) && canActTakedown) && <button onClick={() => onClick(ThreatOptionsCardEnum.FINISH)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <Check />
                <span>Finalizar</span>
            </button>}
            {!showRenounce && <button onClick={() => onClick(ThreatOptionsCardEnum.ASSUME_TAKEDOWN)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <PersonAddAltOutlinedIcon />
                <span>Assumir Takedown</span>
            </button>}
            {showRenounce && <button onClick={() => onClick(ThreatOptionsCardEnum.RENOUNCE_TAKEDOWN)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <PersonRemoveAlt1Outlined />
                <span>Renunciar Takedown</span>
            </button>}
            {((tab == '3') && canActTakedown) && <button onClick={() => onClick(ThreatOptionsCardEnum.REOPEN_THREAT)} className="d-flex gap-2 align-items-center bg-transparent border-0">
                <FileCopy />
                <span>Reabrir ameaça</span>
            </button>}
        </Div>
}

const Div = styled.div<{ operatorView?: boolean, tableIndex?: number, tableLength: number }>`
    background-color: ${dsVariables.colors.Gray[50]};
    ${props =>
        props.tableLength > 8 && (props.tableIndex == props.tableLength - 1 || props.tableIndex == props.tableLength - 2 || props.tableIndex == props.tableLength - 3) ?
            `
                bottom: 30px;
            `
            :
            `
                top: 30px;
            `
    };
    border-radius:${dsVariables.cornerRadius[4]};
    padding:${dsVariables.spacing[8]};
    ${props => !props.operatorView ? `
        right: 0;
        `
        : `
            width: 100%;
            margin-top:10px;
        `
    }
    box-shadow: 0px 0px 8px 0px #00000029;
    white-space:nowrap;
    z-index:2;

    button{
        color: ${dsVariables.colors.Gray[700]};

        &:hover {
            color: ${dsVariables.colors.Astronaut[900]};
        }
    }
`