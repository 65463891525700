import { ThreatDaysEnum } from "../../enums/threat-days.enum";
import { ThreatPlatformEnum } from "../../enums/threat-platform.enum";
import { ThreatTypeEnum } from "../../enums/threat-type.enum";
import { IThreatForm } from "../../interfaces/threat-form.interface";
import { IThreatsFilter } from "../../interfaces/threats-filter.interface";
import { IThreatsTable } from "../../interfaces/threats-table.interface";
import { IThreats } from "../../interfaces/threats.interface";
import { IFraudsQuantityByState } from "../../interfaces/frauds-quantity-by-state.interface";
import { ITotalPagesByState } from "../../interfaces/total-pages-by-state.interface";
import { ITakedowns } from "../../interfaces/takedown.interface";
import { ITakedownsTable } from "../../interfaces/takedowns-table.interface";
import { ITakedownsQuantityByPhase } from "../../interfaces/takedowns-quantity-by-phase.interface";
import { CompaniesComplaintEnum } from "../../enums/companies-complaint.enum";
import { FinancialInstitutionEnum } from "../../enums/financial-institution.enum";
import { PaymentsGatewayEnum } from "../../enums/payments-gateway.enum";
import { FinishFormReasoningEnum } from "../../enums/finish-form-reasoning.enum";

export interface IThreatInitialState {
  takedownsFilter: any;
  id: string;
  filter: IThreatsFilter;
  threatsList: IThreats[];
  threatsTableData: IThreatsTable[];
  forms: IThreatForm;
  currentIds: string[];
  current?: IThreats;
  isLoading: boolean;
  fraudsTotalPagesByState: ITotalPagesByState;
  fraudsQuantityByState: IFraudsQuantityByState;
  fraudsFilteredQuantityByState: IFraudsQuantityByState;
  takedownsQuantityByPhase: ITakedownsQuantityByPhase;
  takedownsTotalPagesByPhase: ITakedownsQuantityByPhase;
  takedownsFilteredQuantityByPhase: ITakedownsQuantityByPhase;
  takedownsList: ITakedowns[];
  takedownsTableData: ITakedownsTable[];
  currentTakedown?: ITakedowns;
  reasoningLabels?: any[]
}

export const ThreatInitialState: IThreatInitialState = {
  id: "",
  filter: {
    ipf: "",
    platform: ThreatPlatformEnum.ALL,
    text: "",
    type: ThreatTypeEnum.ALL,
    days: ThreatDaysEnum.ALL_DAYS,
    status: true,
  },
  takedownsFilter: {
    companyId: "all",
    platform: ThreatPlatformEnum.ALL,
    status: true,
    text: "",
    notifications: null,
    days: ThreatDaysEnum.ALL_DAYS,
    SLA: true,
    timeIdentified: null,
    timeIdentifiedHours: null,
    timeOnPhase: null,
    timeOnPhaseHours: null,
    type: ThreatTypeEnum.ALL,
  },
  threatsList: [],
  takedownsList: [],
  threatsTableData: [],
  takedownsTableData: [],
  forms: {
    adsForm: {
      type: ThreatTypeEnum.FAKE_AD,
      url: "",
      id: "",
      searchDate: new Date(),
      startDate: new Date(),
      platform: ThreatPlatformEnum.METAADS,
      advertiserName: "",
      advertiserId: "",
      instagramProfile: "",
      evidence: [],
      evidenceLink: "",
      isActive: true,
      adsQuantity: 0,
      occurrences: [{ url: '', evidence: { files: [], link: '' }, adId: '',advertiserName: '' }],
    },
    profileForm: {
      type: ThreatTypeEnum.FAKE_PROFILE,
      url: "",
      searchDate: new Date(),
      platform: ThreatPlatformEnum.INSTAGRAM,
      profile: "",
      evidence: [],
      evidenceLink: "",
      isActive: true,
      occurrences: [{ url: '', evidence: { files: [], link: '' } }],
    },
    contentForm: {
      type: ThreatTypeEnum.FAKE_PROFILE,
      url: "",
      platform: ThreatPlatformEnum.INSTAGRAM,
      profile: "",
      evidence: [],
      evidenceLink: "",
      isActive: true,
      occurrences: [{ url: '', evidence: { files: [], link: '' } }],
    },
    siteForm: {
      type: ThreatTypeEnum.FAKE_SITE,
      searchText: "",
      isActive: true,
      occurrences: [{ url: '', evidence: { files: [], link: '' } }],
    },
    registerComplaintForm: {
      complaintChannel: 'Formulário',
      reportDate: '',
      reportedTo: CompaniesComplaintEnum.NONE,
      reportId: ''
    },
    registerPixForm: {
      checkoutUrl: '',
      cpf: '',
      financialInstitution: FinancialInstitutionEnum.NONE,
      paymentGateway: PaymentsGatewayEnum.NONE,
      pix: '',
    },
    takedownFinishForm: {
      reasoning: { text: '', id: '' }
    }
  },
  currentIds: [],
  isLoading: false,
  fraudsQuantityByState: {
    awaiting_approval: 0,
    discarded: 0,
    new: 0,
    quarantine: 0,
    resolved: 0,
    under_discussion: 0,
  },
  fraudsTotalPagesByState: {
    awaiting_approval: 0,
    discarded: 0,
    new: 0,
    quarantine: 0,
    resolved: 0,
    under_discussion: 0,
  },
  fraudsFilteredQuantityByState: {
    awaiting_approval: 0,
    discarded: 0,
    new: 0,
    quarantine: 0,
    resolved: 0,
    under_discussion: 0,
  },
  takedownsQuantityByPhase: {
    request_received: 0,
    complaint_made: 0,
    special_treatment: 0,
    finished: 0,
  },
  takedownsTotalPagesByPhase: {
    request_received: 0,
    complaint_made: 0,
    special_treatment: 0,
    finished: 0,
  },
  takedownsFilteredQuantityByPhase: {
    request_received: 0,
    complaint_made: 0,
    special_treatment: 0,
    finished: 0,
  },
};
