/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import AnalyticalReportAggressorHeatmap from "./AnalyticalReportAggressorHeatmap";
import AnalyticalReportOccurrences from "./AnalyticalReportOccurrences";
import AnalyticalReportTotalAggressiveness from "./AnalyticalReportTotalAggressiveness";
import AnalyticalReportFoundAgressor from "./AnalyticalReportFoundAgressor";
import AnalyticalReportWidgets from "./AnalyticalReportWidgets";
import AnalyticalReportFilter from "./AnalyticalReportFilter";
import AnalyticalReportBranddiScore from "./AnalyticalReportBranddiScore";
import CampaignSelector from "../../../Components/Common/CampaignSelector";
import moment, { locale } from "moment-timezone";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";

import {
  fetchAnalyticalKpisRequest,
  fetchBrandScoresRequest,
  fetchDailyScoresRequest,
  fetchDailyTotalAggressorsRequest,
  fetchOccurrencesTrendRequest,
  fetchOffendersGroupByDomainRequest,
  setAnalyticalEndDate,
  setAnalyticalStartDate,
} from "../../../store/pages/analytical/actions";
import {
  setSelectedCompany,
  setSelectedCampaign,
  getLineAgressors,
  getEventAds as fetchEventAds,
} from "../../../store/actions";
import useCanPerformAction from "../../../DesignSystem/hooks/CanPerformAction.hook";
import { ActionEnum } from "../../../DesignSystem/constants/user-action-permissions.constant";

let DAYS_ALL = 0;
const DAYS_7 = 7;
const DAYS_14 = 14;
const DAYS_28 = 28;
const MAX_DATE = moment()
  .subtract(1, "days")
  .endOf("day")
  .tz("America/Sao_Paulo")
  .toDate();

const AnalyticalReport = () => {
  document.title = "Branddi.com - BrandBidding | Relatório Analítico";
  document.description =
    "Combata o uso indevido da sua marca e maximize seus resultados";
  const dispatch = useDispatch();

  const selectProfileState = (state) => state.Profile;
  const { user, selectedCampaign, selectedCompany } =
    useSelector(selectProfileState);

  const canAccessPage = useCanPerformAction(ActionEnum.everything)

  const { listAll: listAllOffendersGroupByDomain } = useSelector(store => store.AnalyticalReport.offenderGroupByDomain)

  const { getEventAds, ads } = useSelector((state) => state.Mediation);
  const { loading: adsLoading, error: adsError } = getEventAds;

  const selectPanelState = (state) => state.Panel;
  const { panelLoading, line } = useSelector(selectPanelState);
  const selectAnalyticalState = (state) => state.AnalyticalReport;

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const companyId = searchParams.get("companyId");
  const campaignId = searchParams.get("campaignId");
  const INITIAL_DATE = searchParams.get("startDate");
  const FINAL_DATE = searchParams.get("endDate");

  useEffect(() => {
    dispatch(setAnalyticalStartDate(new Date(INITIAL_DATE)));
    dispatch(setAnalyticalEndDate(new Date(FINAL_DATE)));
  }, []);

  const {
    startDate,
    endDate,
    dailyScoreData,
    analyticalKpisData,
    offenderGroupByDomain,
    brandScoresData,
    dailyTotalAggression,
    occurrencesTrend
  } = useSelector(selectAnalyticalState);

  const flatpickrStartDate = useRef(null);
  const flatpickrEndDate = useRef(null);
  const flatpickrOptions = {
    locale: Portuguese, // Define a linguagem como português
    dateFormat: "d/m/Y", // Formato de data usado no Brasil (opcional)
    maxDate: MAX_DATE,
    defaultDate: [INITIAL_DATE, FINAL_DATE],
  };

  const [datesFilter, setDatesFilter] = useState([startDate, endDate]);
  const [selectedPeriod, setSelectedPeriod] = useState(DAYS_14);
  const [selectedPeriodName, setSelectedPeriodName] = useState("14D");

  const [selectedChannelFilter, setSelectedChannelFilter] = useState({
    value: "",
    label: "Todos",
  });
  const [selectedDomainFilter, setSelectedDomainFilter] = useState("");
  const [selectedKeywordFilter, setSelectedKeywordFilter] = useState([]);
  const [selectedPageFilter, setSelectedPageFilter] = useState({
    value: "",
    label: "Todas",
  });
  const [selectedPositionFilter, setSelectedPositionFilter] = useState([]);

  const [heatmapWords, setHeatmapWords] = useState("all");

  const handleStartDateChange = (startDateSelected) => {
    if (endDate === null) return;

    const isEndDateBeforeStartDate =
      moment(startDateSelected).isBefore(endDate);

    if (!isEndDateBeforeStartDate) {
      dispatch(
        setAnalyticalStartDate(
          moment(startDateSelected)
            .endOf("day")
            .tz("America/Sao_Paulo")
            .format()
        )
      );
      flatpickrEndDate.current.flatpickr.setDate(startDateSelected, true);
    }

    dispatch(
      setAnalyticalStartDate(moment(startDateSelected).startOf("day").format())
    );
  };

  const handleEndDateChange = (endDateSelected) => {
    if (startDate === null) return;

    const isEndDateAfterStartDate = moment(endDateSelected).isAfter(startDate);

    if (!isEndDateAfterStartDate) {
      dispatch(
        setAnalyticalStartDate(
          moment(endDateSelected).endOf("day").tz("America/Sao_Paulo").format()
        )
      );
      flatpickrStartDate.current.flatpickr.setDate(endDateSelected, true);
    }

    dispatch(
      setAnalyticalEndDate(
        moment(endDateSelected).endOf("day").tz("America/Sao_Paulo").format()
      )
    );
  };

  const clearPeriod = () => {
    setSelectedPeriod(null);
    setSelectedPeriodName(null);
  };

  const handlePeriodChange = (period) => {
    const totalDays = period;
    const initialDate = new Date();
    initialDate.setDate(initialDate.getDate() - totalDays);
    initialDate.setHours(0, 0, 0, 0);

    const finalDate = new Date(FINAL_DATE);

    flatpickrStartDate.current.flatpickr.setDate(initialDate, true);
    flatpickrEndDate.current.flatpickr.setDate(finalDate, true);

    dispatch(setAnalyticalStartDate(initialDate));
    dispatch(setAnalyticalEndDate(finalDate));

    setDatesFilter([initialDate, finalDate]);
    setSelectedPeriod(period);
    setSelectedPeriodName(period === DAYS_ALL ? "Tudo" : `${period}D`);
  };

  useEffect(() => {
    if (user.companies) {
      const selectedCompany = user.companies.find(
        (item) => item._id === companyId
      );
      if (!selectedCompany) {
        return;
      }

      dispatch(
        setSelectedCompany({
          value: selectedCompany._id,
          label: selectedCompany.name,
          category: user.companies?.find(el => el._id == companyId)?.category,
          isChildCompany: user.companies?.find(el => el._id == companyId)?.isChildCompany
        })
      );
    }
  }, [companyId, user.companies]);

  useEffect(() => {
    if (user.campaigns) {
      const campaign = user.campaigns?.find(
        (campaign) => campaign._id === campaignId
      );
      if (!campaign) {
        return;
      }
      dispatch(
        setSelectedCampaign({
          value: campaign?._id,
          label: campaign?.name,
          createdAt: campaign?.createdAt,
          keywords: campaign?.keywords,
          urlLogo: campaign?.urlLogo,
          pages: campaign?.pages,
        })
      );
    }
  }, [campaignId, user.campaigns]);

  useEffect(() => {
    setSelectedPeriod(Number(searchParams.get("days")));
  }, []);

  useEffect(() => {
    if (startDate) searchParams.set("startDate", startDate);
    if (endDate) searchParams.set("endDate", endDate);

    const newSearch = searchParams.toString();
    if (location.search !== `?${newSearch}`) {
      navigate({ search: newSearch });
    }
  }, [startDate, endDate, navigate, location.search]);

  useEffect(() => {
    searchParams.set("days", selectedPeriod);
    const newSearch = searchParams.toString();
    if (location.search !== `?${newSearch}`) {
      navigate({ search: newSearch });
    }
  }, [selectedPeriod, navigate, location.search, searchParams]);

  useEffect(() => {
    const today = new Date();
    const campaignCreationDate = new Date(selectedCampaign.createdAt);
    const differenceInTime = today.getTime() - campaignCreationDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    DAYS_ALL = Math.ceil(differenceInDays);
    if (selectedPeriodName === "Tudo") {
      handlePeriodChange(DAYS_ALL);
    }
  }, [selectedCampaign.value]);

  useEffect(() => {
    if (!selectedCampaign.value) return;
    dispatch(
      fetchDailyScoresRequest(
        selectedCampaign.value,
        moment(datesFilter[0] || INITIAL_DATE)
          .startOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        moment(datesFilter[1] || FINAL_DATE)
          .endOf("day")
          .tz("America/Sao_Paulo")
          .format()
      )
    );
    dispatch(
      fetchBrandScoresRequest(
        selectedCampaign.value,
        moment(datesFilter[0] || INITIAL_DATE)
          .startOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        moment(datesFilter[1] || FINAL_DATE)
          .endOf("day")
          .tz("America/Sao_Paulo")
          .format()
      )
    );
  }, [datesFilter, dispatch, selectedCampaign.value]);

  useEffect(() => {
    let startDate = datesFilter[0]
      ? moment(datesFilter[0])
      : moment(INITIAL_DATE);
    const endDate = datesFilter[1]
      ? moment(datesFilter[1])
      : moment(FINAL_DATE);

    if (!startDate.isValid() || !endDate.isValid()) {
      console.error("Data inválida em datesFilter");
      return;
    }

    if (!selectedCampaign.value) return

    dispatch(
      fetchOffendersGroupByDomainRequest(
        selectedCampaign.value,
        startDate.startOf("day").tz("America/Sao_Paulo").format(),
        endDate.endOf("day").tz("America/Sao_Paulo").format(),
        selectedChannelFilter?.value,
        selectedPageFilter?.value,
        selectedPositionFilter?.length <= 0
          ? undefined
          : selectedPositionFilter,
        selectedKeywordFilter?.length <= 0
          ? undefined
          : selectedKeywordFilter.join("|"),
        heatmapWords,
        listAllOffendersGroupByDomain
      )
    );
  }, [
    datesFilter,
    selectedCampaign.value,
    selectedChannelFilter,
    selectedDomainFilter,
    selectedPageFilter,
    selectedPositionFilter,
    selectedKeywordFilter,
    heatmapWords,
    listAllOffendersGroupByDomain
  ]);

  useEffect(() => {
    if (!selectedCampaign.value) return
    dispatch(
      fetchAnalyticalKpisRequest(
        selectedCampaign.value,
        moment(datesFilter[0] || INITIAL_DATE)
          .startOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        moment(datesFilter[1] || FINAL_DATE)
          .endOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        selectedChannelFilter?.value,
        selectedDomainFilter?.value,
        selectedPageFilter?.value,
        selectedPositionFilter?.length <= 0
          ? undefined
          : selectedPositionFilter,
        selectedKeywordFilter?.length <= 0
          ? undefined
          : selectedKeywordFilter.join("|")
      )
    );
    dispatch(
      getLineAgressors(
        selectedCampaign.value,
        moment(datesFilter[0] || INITIAL_DATE)
          .startOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        moment(datesFilter[1] || FINAL_DATE)
          .endOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        selectedChannelFilter?.value,
        selectedDomainFilter?.value,
        selectedPageFilter?.value,
        selectedPositionFilter?.length <= 0
          ? undefined
          : selectedPositionFilter,
        selectedKeywordFilter?.length <= 0
          ? undefined
          : selectedKeywordFilter.join("|")
      )
    );
  }, [
    datesFilter,
    dispatch,
    selectedCampaign.value,
    selectedChannelFilter,
    selectedDomainFilter,
    selectedPageFilter,
    selectedPositionFilter,
    selectedKeywordFilter,
  ]);

  useEffect(() => {
    if (!selectedCampaign.value) return
    dispatch(
      fetchOccurrencesTrendRequest(
        selectedCampaign.value,
        moment(datesFilter[0] || INITIAL_DATE)
          .startOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        moment(datesFilter[1] || FINAL_DATE)
          .endOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        selectedChannelFilter?.value,
        selectedDomainFilter?.value,
        selectedPageFilter?.value,
        selectedPositionFilter?.length <= 0
          ? undefined
          : selectedPositionFilter,
        selectedKeywordFilter?.length <= 0
          ? undefined
          : selectedKeywordFilter.join("|")
      )
    );

    dispatch(
      fetchDailyTotalAggressorsRequest(
        selectedCampaign.value,
        moment(datesFilter[0] || INITIAL_DATE)
          .startOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        moment(datesFilter[1] || FINAL_DATE)
          .endOf("day")
          .tz("America/Sao_Paulo")
          .format(),
        selectedChannelFilter?.value,
        selectedDomainFilter?.value,
        selectedPageFilter?.value,
        selectedPositionFilter?.length <= 0
          ? undefined
          : selectedPositionFilter,
        selectedKeywordFilter?.length <= 0
          ? undefined
          : selectedKeywordFilter.join("|")
      )
    );
  }, [
    datesFilter,
    dispatch,
    selectedCampaign.value,
    selectedChannelFilter,
    selectedDomainFilter,
    selectedPageFilter,
    selectedPositionFilter,
    selectedKeywordFilter,
  ]);
  const filteredData = dailyScoreData.data.filter((doc) => {
    const channelFilter = selectedChannelFilter.value
      ? doc.channels.includes(selectedChannelFilter.value)
      : true;

    const domainFilter = selectedDomainFilter.value
      ? doc.domain === selectedDomainFilter.value
      : true;

    const keywordFilter =
      selectedKeywordFilter.length > 0
        ? selectedKeywordFilter.every((keyword) =>
          doc.keywords.includes(keyword)
        )
        : true;

    let pages = [];

    if (Array.isArray(doc.firstPage) && Array.isArray(doc.secondPage)) {
      if (selectedPageFilter.value === "1") {
        pages = doc.firstPage;
      } else if (selectedPageFilter.value === "2") {
        pages = doc.secondPage;
      } else {
        pages = [...doc.firstPage, ...doc.secondPage];
      }
    }

    const pageFilter = pages.length > 0;

    const positionFilter =
      selectedPositionFilter.length > 0
        ? selectedPositionFilter.every((position) => pages.includes(position))
        : true;

    return (
      channelFilter &&
      domainFilter &&
      keywordFilter &&
      pageFilter &&
      positionFilter
    );
  });

  const handleAds = (campaignId, domain) => {
    dispatch(fetchEventAds(campaignId, domain));
  };

  const HeatMapFiltered = offenderGroupByDomain.data.filter((doc) => {
    const domainFilter = selectedDomainFilter.value
      ? doc.label.text === selectedDomainFilter.value
      : true;
    return domainFilter;
  });

  useEffect(() => {
    return () => {
      // Redefine as datas no Redux ao desmontar o componente(sair da pagina)
      dispatch(setAnalyticalStartDate(null));
      dispatch(setAnalyticalEndDate(null));
    };
  }, []);

  if (!canAccessPage) return <p>Vc não tem permissão para acessar essa funcionalidade</p>

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            path={[
              { title: "Brand Bidding", link: "/" },
              { title: "Relatório Analítico", link: "" },
            ]}
          />

          <Row className="d-flex justify-content-end mb-3">
            <Col md="6">
              <CampaignSelector
                panelLoading={panelLoading}
                user={user}
                selectedCampaign={selectedCampaign}
                selectedCompany={selectedCompany}
              />
            </Col>

            <Col md="5">
              <div className="d-flex gap-1">
                <div className="input-group d-flex">
                  <Flatpickr
                    className="form-control border-0 shadow pe-auto"
                    options={{
                      dateFormat: "d M, Y",
                      locale: Portuguese,
                      value: startDate,
                      defaultDate: startDate
                        ? moment(startDate).format()
                        : new Date(INITIAL_DATE),
                      maxDate: MAX_DATE,
                      disableMobile: "true",
                    }}
                    onChange={(date) => {
                      setDatesFilter([date[0], datesFilter[1]]);
                      handleStartDateChange(date[0]);
                      clearPeriod();
                    }}
                    ref={flatpickrStartDate}
                  />

                  <Flatpickr
                    className="form-control border-0 shadow pe-auto"
                    options={{
                      dateFormat: "d M, Y",
                      locale: Portuguese,
                      value: endDate,
                      defaultDate: endDate
                        ? moment(endDate)
                          .endOf("day")
                          .tz("America/Sao_Paulo")
                          .format()
                        : new Date(FINAL_DATE),
                      maxDate: MAX_DATE,
                      disableMobile: "true",
                    }}
                    onChange={(date) => {
                      setDatesFilter([datesFilter[0], date[0]]);
                      handleEndDateChange(date[0]);
                      clearPeriod();
                    }}
                    ref={flatpickrEndDate}
                  />
                  <button className="btn btn-primary text-white pe-none">
                    <i className="ri-calendar-2-line"></i>
                  </button>
                </div>
                <div className="d-flex gap-1">
                  <button
                    type="button"
                    onClick={() => handlePeriodChange(DAYS_7)}
                    className={
                      selectedPeriod === DAYS_7
                        ? "btn btn-soft-primary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                  >
                    7D
                  </button>
                  <button
                    type="button"
                    onClick={() => handlePeriodChange(DAYS_14)}
                    className={
                      selectedPeriod === DAYS_14
                        ? "btn btn-soft-primary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                  >
                    14D
                  </button>
                  <button
                    type="button"
                    onClick={() => handlePeriodChange(DAYS_28)}
                    className={
                      selectedPeriod === DAYS_28
                        ? "btn btn-soft-primary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                  >
                    28D
                  </button>
                  <button
                    type="button"
                    onClick={() => handlePeriodChange(DAYS_ALL)}
                    className={
                      selectedPeriod === DAYS_ALL
                        ? "btn btn-soft-primary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                  >
                    Tudo
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <AnalyticalReportBranddiScore brandScoresData={brandScoresData} />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <AnalyticalReportFilter
                setSelectedChannelFilter={setSelectedChannelFilter}
                selectedPageFilter={selectedPageFilter}
                selectedDomainFilter={selectedDomainFilter}
                setSelectedDomainFilter={setSelectedDomainFilter}
                selectedKeywordFilter={selectedKeywordFilter}
                setSelectedKeywordFilter={setSelectedKeywordFilter}
                selectedPositionFilter={selectedPositionFilter}
                setSelectedPageFilter={setSelectedPageFilter}
                setSelectedPositionFilter={setSelectedPositionFilter}
                selectedCampaign={selectedCampaign}
                selectedChannelFilter={selectedChannelFilter}
                dailyScoreData={dailyScoreData}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <AnalyticalReportWidgets
                analyticalKpisData={analyticalKpisData.data}
                loading={analyticalKpisData.loading}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <AnalyticalReportFoundAgressor foundAggressors={line} />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <AnalyticalReportAggressorHeatmap
                offenderGroupByDomainData={HeatMapFiltered}
                offenderGroupByDomainLoading={offenderGroupByDomain.loading}
                setHeatmapWords={setHeatmapWords}
                selectedCampaign={selectedCampaign}
                adsLoading={adsLoading}
                adsError={adsError}
                ads={ads}
                handleAds={handleAds}
                selectedPageFilter={selectedPageFilter}
                selectedPositionFilter={selectedPositionFilter}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <AnalyticalReportTotalAggressiveness
                dailyTotalAggression={dailyTotalAggression.data}
                dailyTotalAggressionLoading={dailyTotalAggression.loading}
                startDate={datesFilter[0] ? datesFilter[0] : INITIAL_DATE}
                endDate={datesFilter[1] ? datesFilter[1] : FINAL_DATE}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <AnalyticalReportOccurrences
                occurrencesTrendLoading={occurrencesTrend.loading}
                occurrencesTrend={occurrencesTrend.data}
                startDate={datesFilter[0] ? datesFilter[0] : INITIAL_DATE}
                endDate={datesFilter[1] ? datesFilter[1] : FINAL_DATE}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AnalyticalReport;
