import { call, put, takeLatest, takeLeading } from "redux-saga/effects";

import {
  getAnalyticalKpis,
  getBrandScores,
  getDailyScores,
  getDailyTotalAggression,
  getOccurrencesTrend,
  getOffendersGroupByDomain,
} from "../../../helpers/backend_helper";
import {
  fetchAnalyticalKpisFailure,
  fetchAnalyticalKpisSuccess,
  fetchBrandScoresFailure,
  fetchBrandScoresSuccess,
  fetchDailyScoresFailure,
  fetchDailyScoresSuccess,
  fetchDailyTotalAggressorsFailure,
  fetchDailyTotalAggressorsSuccess,
  fetchOccurrencesTrendSuccess,
  fetchOffendersGroupByDomainFailure,
  fetchOffendersGroupByDomainSuccess,
} from "./actions";
import {
  FETCH_ANALYTICAL_KPIS_REQUEST,
  FETCH_BRAND_SCORES_REQUEST,
  FETCH_DAILY_SCORES_REQUEST,
  FETCH_DAILY_TOTAL_AGRESSORS_REQUEST,
  FETCH_OCCURRENCES_TREND_FAILURE,
  FETCH_OCCURRENCES_TREND_REQUEST,
  FETCH_OFFENDERS_GROUP_BY_DOMAIN_REQUEST,
} from "./actionsTypes";

function* fetchDailyScoreSaga({ payload: { campaignId, startDate, endDate } }) {
  try {
    const dailyScore = yield call(getDailyScores, {
      campaignId,
      startDate,
      endDate,
    });
    yield put(fetchDailyScoresSuccess(dailyScore));
  } catch (e) {
    yield put(fetchDailyScoresFailure(e.message));
  }
}

function* fetchDailyTotalAggressorsSaga({
  payload: { campaignId,
    startDate,
    endDate,
    type,
    domain,
    pageFound,
    position,
    words, },
}) {
  try {
    let payload = {
      campaignId,
      startDate,
      endDate,
    };
    if (type) {
      payload.type = type;
    }
    if (domain) {
      payload.domain = domain;
    }
    if (pageFound) {
      payload.pageFound = pageFound;
    }
    if (position) {
      payload.position = position;
    }
    if (words) {
      payload.words = words;
    }
    const dailyTotalAggressors = yield call(getDailyTotalAggression, payload);
    yield put(fetchDailyTotalAggressorsSuccess(dailyTotalAggressors));
  } catch (e) {
    yield put(fetchDailyTotalAggressorsFailure(e.message));
  }
}

function* getAnalyticalKpisSaga({
  payload: {
    campaignId,
    startDate,
    endDate,
    type,
    domain,
    pageFound,
    position,
    words,
  },
}) {
  try {
    let payload = {
      campaignId,
      startDate,
      endDate,
    };

    if (type) {
      payload.type = type;
    }

    if (domain) {
      payload.domain = domain;
    }

    if (pageFound) {
      payload.pageFound = pageFound;
    }

    if (position) {
      payload.position = position;
    }

    if (words) {
      payload.words = words;
    }

    const analyticalKpis = yield call(getAnalyticalKpis, payload);
    yield put(fetchAnalyticalKpisSuccess(analyticalKpis));
  } catch (e) {
    yield put(fetchAnalyticalKpisFailure(e.message));
  }
}

function* getOffendersGroupByDomainSaga({
  payload: {
    campaignId,
    startDate,
    endDate,
    type,
    pageFound,
    position,
    words,
    keywordType,
    listAll
  },
}) {
  try {
    let payload = {
      campaignId,
      startDate,
      endDate,
      keywordType,
      listAll
    };

    if (type) {
      payload.type = type;
    }

    if (pageFound) {
      payload.pageFound = pageFound;
    }

    if (position) {
      payload.position = position;
    }

    if (words) {
      payload.words = words;
    }
    const offendersGroupByDomain = yield call(
      getOffendersGroupByDomain,
      payload
    );
    yield put(fetchOffendersGroupByDomainSuccess(offendersGroupByDomain));
  } catch (e) {
    yield put(fetchOffendersGroupByDomainFailure(e.message));
  }
}

function* getBrandScoresSaga({ payload: { campaignId, startDate, endDate } }) {
  try {
    const brandScores = yield call(getBrandScores, {
      campaignId,
      startDate,
      endDate,
    });
    yield put(fetchBrandScoresSuccess(brandScores));
  } catch (e) {
    yield put(fetchBrandScoresFailure(e.message));
  }
}

function* getOccurrencesTrendSaga({
  payload: {
    campaignId,
    startDate,
    endDate,
    type,
    domain,
    pageFound,
    position,
    words, },
}) {
  try {
    let payload = {
      campaignId,
      startDate,
      endDate,
    };
    if (type) {
      payload.type = type;
    }
    if (domain) {
      payload.domain = domain;
    }
    if (pageFound) {
      payload.pageFound = pageFound;
    }
    if (position) {
      payload.position = position;
    }
    if (words) {
      payload.words = words;
    }
    const occurrencesTrend = yield call(getOccurrencesTrend, payload);
    yield put(fetchOccurrencesTrendSuccess(occurrencesTrend));
  } catch (e) {
    yield put(FETCH_OCCURRENCES_TREND_FAILURE(e.message));
  }
}

function* analyticalSaga() {
  yield takeLeading(FETCH_DAILY_SCORES_REQUEST, fetchDailyScoreSaga);
  yield takeLeading(
    FETCH_DAILY_TOTAL_AGRESSORS_REQUEST,
    fetchDailyTotalAggressorsSaga
  );
  yield takeLeading(FETCH_ANALYTICAL_KPIS_REQUEST, getAnalyticalKpisSaga);
  yield takeLeading(
    FETCH_OFFENDERS_GROUP_BY_DOMAIN_REQUEST,
    getOffendersGroupByDomainSaga
  );
  yield takeLeading(FETCH_OCCURRENCES_TREND_REQUEST, getOccurrencesTrendSaga);
  yield takeLeading(FETCH_BRAND_SCORES_REQUEST, getBrandScoresSaga);
}

export default analyticalSaga;
