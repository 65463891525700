import styled from "styled-components"
import { dsVariables } from "../variables"
import Caption from "./Text/Caption"

export type TTagType = 'alert' | 'success' | 'info' | 'negative'

interface IProps {
    label: string
    filled?: boolean
    type?: TTagType
    textColor?: string
    backgroundColor?: string
}

export default function Tag({ label, filled = true, type = 'info', textColor, backgroundColor }: IProps) {
    return (
        <Div className="d-flex justify-content-center align-items-center" filled={filled} type={type} textColor={textColor} backgroundColor={backgroundColor}>
            <Caption style="medium">{label}</Caption>
        </Div>
    )
}

const Div = styled.div<{ filled: boolean, type: TTagType, backgroundColor?: string, textColor?: string }>`
    min-width: 73px;
    height: ${dsVariables.spacing[20]};
    padding: ${dsVariables.spacing[4]} ${dsVariables.spacing[8]};
    border-radius: ${dsVariables.spacing[4]};
    width:fit-content;

    ${props => {
        if (props.textColor && props.backgroundColor){
            return `
                background-color: ${props.backgroundColor};
                color: ${props.textColor};
            `
        }

            if (props.filled) {
                switch (props.type) {
                    case 'alert':
                        return `
                        background-color: ${dsVariables.colors.Orange[100]};
                        color: ${dsVariables.colors.Orange[700]};
                    `
                    case 'success':
                        return `
                        background-color: ${dsVariables.colors.Green[200]};
                        color: ${dsVariables.colors.Green[700]};
                    `
                    case 'negative':
                        return `
                        background-color: ${dsVariables.colors.Red[200]};
                        color: ${dsVariables.colors.Red[700]};
                    `
                    case 'info':
                    default:
                        return `
                        background-color: ${dsVariables.colors.Gray[200]};
                        color: ${dsVariables.colors.Gray[700]};
                    `
                }
            } else {
                switch (props.type) {
                    case 'alert':
                        return `
                        border: 1px solid ${dsVariables.colors.Orange[700]};
                        color: ${dsVariables.colors.Orange[700]};
                    `
                    case 'success':
                        return `
                        border: 1px solid ${dsVariables.colors.Green[700]};
                        color: ${dsVariables.colors.Green[700]};
                    `
                    case 'negative':
                        return `
                        border: 1px solid ${dsVariables.colors.Red[700]};
                        color: ${dsVariables.colors.Red[700]};
                    `
                    case 'info':
                    default:
                        return `
                        border: 1px solid ${dsVariables.colors.Gray[200]};
                        color: ${dsVariables.colors.Gray[700]};
                    `
                }
            }
    }}
`