import styled from "styled-components";
import { dsVariables } from "../../variables";
import SelectFilterSection from "./SelectFilterSection";
import { ThreatPlatformEnum } from "../../../enums/threat-platform.enum";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../interfaces/store.interface";
import { ThreatStatusActiveEnum } from "../../../enums/threat-status-active.enum";
import { ThreatTypeTextEnum } from "../../../enums/threat-type-text.enum";
import { getThreatType } from "../../utils/get-threat-type.util";
import { useLocation, useNavigate } from "react-router-dom";
import { getThreatTypeText } from "../../utils/get-threat-type-text.util";
import ButtonTertiary from "../Button/ButtonTertiary";
import { DeleteOutline } from "@mui/icons-material";
import { ThreatTypeEnum } from "../../../enums/threat-type.enum";
import Input from "../Input/Input";
import { changeFilterIdentifiedHoursTakedown, changeFilterIdentifiedTakedown, changeFilterNotificationsTakedown, changeFilterTimePhaseHoursTakedown, changeFilterTimePhaseTakedown } from "../../../store/threats/actions";
import { TakedownTextSlaEnum } from "../../../enums/takedown-sla-text.enum";
import { TakedownSlaEnum } from "../../../enums/takedown-sla.enum";

export default function TakedownsFilterSection() {
  const { filter, user } = useSelector((store: IStore) => {
    return {
      filter: store.Threats.takedownsFilter,
      user: store.Profile.user,
    };
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isCleanButtonDisabled =
    searchParams.get("type") == ThreatTypeEnum.ALL &&
    searchParams.get("platform") == ThreatPlatformEnum.ALL &&
    !searchParams.get("status");

  function changeType(e: any) {
    searchParams.set("type", getThreatType(e.target.value));
    searchParams.set("page", "1");
    const newSearch = searchParams.toString();

    if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
  }

  function changePlatform(e: any) {
    searchParams.set("platform", e.target.value);
    searchParams.set("page", "1");
    const newSearch = searchParams.toString();

    if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
  }

  function changeStatus(e: any) {
    searchParams.set("status", e.target.value);
    searchParams.set("page", "1");
    const newSearch = searchParams.toString();

    if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
  }

  function changeSLA(e: any) {
    searchParams.set("sla", e.target.value == TakedownTextSlaEnum.EXPIRED ? TakedownSlaEnum.EXPIRED : TakedownSlaEnum.UP_TO_DATE);
    searchParams.set("page", "1");
    const newSearch = searchParams.toString();

    if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
  }

  function clearFilter() {
    searchParams.set("type", ThreatTypeEnum.ALL);
    searchParams.set("platform", ThreatPlatformEnum.ALL);
    searchParams.delete("status");
    searchParams.delete("companyId");
    searchParams.delete("sla");
    searchParams.delete("identified");
    searchParams.delete("timePhase");
    searchParams.delete("notifications");
    searchParams.delete("identifiedHours");
    searchParams.delete("timePhaseHours");
    dispatch(changeFilterNotificationsTakedown(0));

    const newSearch = searchParams.toString();

    if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
  }

  function changeCompany(e: any) {
    const companyName = e.target.value;

    if (companyName != "Todos") {
      const selectedCompanyId = user.companies.find((el: any) => el.name == companyName)._id;
      searchParams.set("companyId", selectedCompanyId);
      searchParams.set("page", "1");
      const newSearch = searchParams.toString();

      if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    } else {
      searchParams.delete("companyId");
      searchParams.set("page", "1");

      const newSearch = searchParams.toString();
      if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }
  }

  function handleTextFilterIdentifiedInput(e: any) {
    dispatch(changeFilterIdentifiedTakedown(e.target.value));
    setTimeout(() => {
      searchParams.set("identified", e.target.value);
      searchParams.set("page", "1");

      const newSearch = searchParams.toString();

      if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, 1000);
  }

  function handleTextFilterIdentifiedHoursInput(e: any) {
    dispatch(changeFilterIdentifiedHoursTakedown(e.target.value));
    setTimeout(() => {
      searchParams.set("identifiedHours", e.target.value);
      searchParams.set("page", "1");

      const newSearch = searchParams.toString();

      if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, 1000);
  }

  function handleTextFilterTimePhaseInput(e: any) {
    dispatch(changeFilterTimePhaseTakedown(e.target.value));
    setTimeout(() => {
      searchParams.set("timePhase", e.target.value);
      searchParams.set("page", "1");

      const newSearch = searchParams.toString();

      if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, 1000);
  }

  function handleTextFilterTimePhaseHoursInput(e: any) {
    dispatch(changeFilterTimePhaseHoursTakedown(e.target.value));
    setTimeout(() => {
      searchParams.set("timePhaseHours", e.target.value);
      searchParams.set("page", "1");

      const newSearch = searchParams.toString();

      if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, 1000);
  }

  function handleTextFilterNotificationsInput(e: any) {
    dispatch(changeFilterNotificationsTakedown(e.target.value));
    setTimeout(() => {
      searchParams.set("notifications", e.target.value);
      searchParams.set("page", "1");

      const newSearch = searchParams.toString();

      if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, 1000);
  }

  return (
    <Section className={`position-absolute z-1 end-0 top-0 bg-white`}>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-3 flex-wrap">
          <SelectFilterSection
            value={user.companies.find((el: any) => el._id == filter.companyId)?.name}
            label="Marcas (Clientes)"
            onChange={changeCompany}
            options={["Todos"].concat(
              user?.companies?.map((el: any) => el.name)
                .sort((a: string, b: string) => a.localeCompare(b))
            )}
          />
          <SelectFilterSection
            value={getThreatTypeText(filter.type)}
            label="Tipo de ameaça"
            onChange={changeType}
            options={Object.values(ThreatTypeTextEnum)}
          />
          <SelectFilterSection
            value={filter.platform}
            label="Plataforma"
            onChange={changePlatform}
            options={Object.values(ThreatPlatformEnum)}
          />
          <SelectFilterSection
            value={searchParams.get("status")!}
            label="Status"
            onChange={changeStatus}
            options={["Todos"].concat(Object.values(ThreatStatusActiveEnum))}
          />
        </div>

        <div className="d-flex gap-3 flex-wrap" style={{ marginTop: "10px" }}>
          <div className="d-flex flex-column gap-2">
            <label className="mb-0">Notificações</label>
            <Input
              width={203}
            //   value={searchParams.get("notifications")}
              value={filter?.notifications}
              onChange={handleTextFilterNotificationsInput}
              placeholder="Insira a Quantidade"
              type="number"
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex">
              <SelectFilterSection
                value={!searchParams.get("sla") ? 'Todos' :  searchParams.get("sla") == TakedownSlaEnum.EXPIRED ?  TakedownTextSlaEnum.EXPIRED : TakedownTextSlaEnum.UP_TO_DATE}
                label="Prazo SLA"
                onChange={changeSLA}
                options={["Todos"].concat(Object.values(TakedownTextSlaEnum))}
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            <label className="mb-0">Identificado há</label>
            <div className="d-flex">
              <Input
                // minwidth={100}
                width={102}
                // value={searchParams.get("identified")!}
                value={filter?.timeIdentified?.trim()}
                onChange={handleTextFilterIdentifiedInput}
                placeholder="00 dia(s)"
              />
              <Input
                width={101}
                // value={searchParams.get("identifiedHours")!}
                value={filter?.timeIdentifiedHours?.trim()}
                onChange={handleTextFilterIdentifiedHoursInput}
                placeholder="00"
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            <label className="mb-0">Tempo na fase</label>
            <div className="d-flex">
              <Input
                width={102}
                // value={searchParams.get("timePhase")!}
                value={filter?.timeOnPhase?.trim()}
                onChange={handleTextFilterTimePhaseInput}
                placeholder="00 dia(s)"
              />
              <Input
                width={101}
                // value={searchParams.get("timePhaseHours")!}
                value={filter?.timeOnPhaseHours?.trim()}
                onChange={handleTextFilterTimePhaseHoursInput}
                placeholder="00"
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end" style={{ marginTop: "10px" }}>
          <ButtonTertiary
            state={isCleanButtonDisabled ? "disabled" : "default"}
            iconLeftColor={dsVariables.colors.Red[700]}
            iconLeft={DeleteOutline}
            type="destructive"
            onClick={clearFilter}
            label="Limpar"
          />
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  margin-top: 40px;
  width: 900px;
  padding: ${dsVariables.spacing[16]};
  border-radius: ${dsVariables.cornerRadius[4]};
  border: 1px solid ${dsVariables.colors.Gray[200]};
  ${dsVariables.shadows.Down.Blur2}

  @media (max-width: 1111px) {
    max-width: 80vw;
  }

  @media (max-width: 435px) {
    left:0 !important;
  }


`;
